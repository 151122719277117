<template>
  <div class="main">
    <div id="mapDiv"
         class="mapDiv"
         ref="mapDiv"
         @click="onClickMap">
    </div>
    <div class="meng"
         v-if="mengVisible"
         @click.stop>
      <div class="item">
        <!-- <Icon type="md-refresh" /> -->
        <Icon type="md-sync"
              class="meng-icon" />
        <div class="line"></div>
        <div>
          正在绘制{{drawDate}}的轨迹
        </div>
      </div>
    </div>
    <div class="top-title">
      <span @click.stop="onClickIndexName">{{indexName}}</span>
    </div>
    <div class="hint-box">
      <Icon type="md-close"
            size="12"
            class="hint-box-icon" />
      <div class="rect">
        <p>作业网格</p>
        <span v-if="jobGrid"
              style="color:#fff">绑定</span>
        <span v-else>未绑</span>
      </div>
      <div class="rect">
        <p>电子围栏</p>
        <span v-if="fence"
              style="color:#fff">绑定</span>
        <span v-else>未绑</span>
      </div>
      <div class="line">
        <p>作业轨迹</p><span><samp></samp></span>
      </div>
      <div class="line">
        <p>行进轨迹</p><span><samp></samp></span>
      </div>
      <div class="combine">
        <p>违规路线/点</p><span><samp></samp><em></em></span>
      </div>
      <div class="combine">
        <p>报警线路/点</p><span><samp></samp><em></em></span>
      </div>
    </div>
    <TrackLeft :speed="speed"
               :oil-mass="oilMass"
               :oldType="oldType"
               :oilMassTotal="oilMassTotal"
               :mileage="mileage"
               :emptyMileage="emptyMileage"
               :homeworkMileage="homeworkMileage"
               :oilMassHundred="oilMassHundred"
               :name="name"
               :car-type="carType"
               :date-interval="dateInterval"
               :pointtimestamp="pointtimestamp"
               :pointlatlon="pointlatlon"
               @setDateInterval="setDateInterval"
               @getInquireTrack="getInquireTrack"
               @setDateInterval2="setDateInterval2"
               @onClickBin="onClickBin"></TrackLeft>
    <TrackRight :date-interval="dateInterval"
                :car-id="id"
                :oldType="oldType"
                :dateList="dateList"
                :mapArr="map"
                @onClickTrackNodeDetailItem="onClickTrackNodeDetailItem"
                @onWorkGridSwitch="onWorkGridSwitch"
                @onDot="onDot"
                @onPath="onPath"
                @onIsDrivingTrack="onIsDrivingTrack"
                @onIsAnimation="onIsAnimation"
                @onCorrectTrack="onCorrectTrack"
                @onCorrectTrackIsShow="onCorrectTrackIsShow"
                @onBinDotIsShow="onBinDotIsShow"
                @onChangeTrackTableData="onChangeTrackTableData"></TrackRight>
    <div class="progress">
      <div class="progress-box">
        <div class="progress-box-left">
          <p>速度</p>
          <Select v-model="mSpeed"
                  @on-change="setSpeed"
                  style="width:65px">
            <Option v-for="item in speedArr"
                    :value="item.id"
                    :key="item.id">{{ item.name }}
            </Option>
          </Select>
        </div>
        <div class="progress-box-icon"
             @click="trackStart">
          <Icon custom="i-icon icon-xiayiye"
                size="12"
                color="rgba(255, 255, 255, 0.85)"
                class="progress-box-icon-icon"
                v-show="!pause" />

          <Icon custom="i-icon icon-zanting"
                size="12"
                color="rgba(255, 255, 255, 0.85)"
                class="progress-box-icon-icon"
                v-show="pause" />
        </div>
        <div class="progress-box-slider">
          <Slider v-model="slider"
                  show-tip="never"
                  @on-change="sliderData"></Slider>
        </div>
        <div class="progress-box-detail"
             @click.stop="detailVisible = !detailVisible">
          <span>更多详情</span>
          <Icon custom="i-icon icon-zhankai2"
                size="16"
                color="rgba(255, 255, 255, 0.65)"
                class="progress-box-detail-icon"
                :class="{'progress-box-detail-icon-in':detailVisible}" />
        </div>
      </div>
      <div class="detail-box"
           v-if="detailVisible">
        <Tabs v-model="tabsIndex">
          <TabPane label="状态曲线"
                   v-if="oldType != 'person'"></TabPane>
          <TabPane label="轨迹详情"></TabPane>
          <TabPane label="报警数据"></TabPane>
          <TabPane label="违规数据"></TabPane>
        </Tabs>
        <div v-if="oldType != 'person' ? tabsIndex == 0: false">
          <div class="noData"
               v-if="loading">
            <img src="../../assets/main/loading.gif">
          </div>
          <div v-else-if="slOptions.series[0].data.length>0 && slOptions.series[1].data.length>0 ">
            <Echarts width="100%"
                     height="120px"
                     key="sj"
                     ref="oilRef"
                     :options="slOptions"></Echarts>
          </div>
          <div class="noData"
               v-else>
            <img src="../../assets/main/blank_black.png">
            <p>无数据</p>
          </div>
        </div>
        <div v-if="oldType != 'person' ? tabsIndex == 1: tabsIndex == 0 "
             class="detail-box-track">
          <div>
            <Table :columns="trackColumns"
                   :data="trackData"
                   height="277"
                   v-if="trackData.length>0"></Table>

            <div class="noData"
                 v-else>
              <img src="../../assets/main/blank_black.png">
              <p>无数据</p>
            </div>
            <!-- <div class="flex j-between a-center"
                 style="padding: 0 10px"> -->
            <Page :total="trackTotalPage"
                  show-total
                  show-sizer
                  show-elevator
                  @on-change="onPageChange"
                  @on-page-size-change="onPageSizeChange"
                  :current="trackPageNum"
                  :page-size="trackPageSize"
                  style="padding: 10px 0;color: #fff;"
                  class="flex-1" />
            <!-- <Select v-model="trackTableSelect"
                      style="width:160px;"
                      @on-change="onChangeTrackTableData">
                <Option v-for="(item,index) in dateList"
                        :value="index"
                        :key="index">{{ item.date }}</Option>
              </Select> -->
            <!-- </div> -->
          </div>
        </div>
        <div v-if="oldType != 'person' ? tabsIndex == 2: tabsIndex == 1"
             class="detail-box-track">
          <div v-if="alarmData.length>0">
            <Table :columns="alarmColumns"
                   :data="alarmData"
                   height="330"></Table>
          </div>
          <div class="noData"
               v-else>
            <img src="../../assets/main/blank_black.png">
            <p>无数据</p>
          </div>
        </div>
        <div v-if="oldType != 'person' ? tabsIndex == 3: tabsIndex == 2"
             class="detail-box-track">
          <div v-if="warningData.length>0">
            <Table :columns="warningColumns"
                   :data="warningData"
                   height="330"></Table>
          </div>
          <div class="noData"
               v-else>
            <img src="../../assets/main/blank_black.png">
            <p>无数据</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import tMap from '../../utils/tMap'
import TrackLeft from '@/components/product/main/layout/TrackLeft.vue'
import TrackRight from '@/components/product/main/layout/TrackRight.vue'
import Echarts from '../../components/common/Echarts';
import runAlarmVue from '../admin/patrolMaintain/runAlarm.vue'
import bus from '@/utils/bus'
import { getDateTomorrow, formatDate, formatTime } from '@/utils/dateRangUtil'

export default {
  name: 'Track',
  components: {
    TrackLeft,
    TrackRight,
    Echarts
  },
  data () {
    return {
      oilMass: 0,
      oilMassTotal: 0,
      oilMassHundred: 0,
      emptyMileage: 0,
      homeworkMileage: 0,
      mileage: 0,
      speed: 0,
      T: '', // 地图
      zoom: 0,
      map: '',
      maps: null,
      _CarTrack: '',
      oldType: '',
      carType: '',
      id: '',
      name: '',
      nameText: '',
      starttime: '',
      endtime: '',
      pause: false,
      mSpeed: 1000,
      passOneNodeCount: 0,
      dateInterval: [],
      loading: false,
      speedArr: [
        {
          id: 1000,
          name: '1x'
        },
        {
          id: 500,
          name: '2x'
        },
        {
          id: 250,
          name: '4x'
        },
        // {
        //   id: 10,
        //   name: '100x'
        // },
      ],
      slider: 0,
      slider2: 0,
      detailVisible: false,
      slOptions: {
        legend: {
          data: ['速度曲线(km/h)', '油量曲线(L)'],
          left: 10,
          textStyle: {
            fontSize: 12,//字体大小
            color: '#ffffff'//字体颜色
          },
          itemHeight: 5
        },
        tooltip: {
          trigger: 'axis',
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: []
        },
        yAxis: {
          type: 'value',
          data: [],
          splitLine: false
        },
        series: [{
          name: '速度曲线(km/h)',
          data: [],
          type: 'line',
          areaStyle: {
            color: '#37A2FF'
          },
          itemStyle: {
            color: '#37A2FF'
          },
          smooth: true
        }, {
          name: '油量曲线(L)',
          data: [],
          type: 'line',
          areaStyle: {
            color: '#FFBF00'
          },
          itemStyle: {
            color: '#FFBF00'
          },
          smooth: true
        }
        ],
        grid: {
          x: 0,
          y: 0,
          x2: 0,
          y2: 0,
          top: '10%',
          borderWidth: 0
        },
      },
      tabsIndex: 0,
      trackColumns: [{
        title: '序号',
        type: 'index',
        width: 60,
        align: 'center'
      },
      {
        title: '通讯时间',
        key: 'timestamp'
      }, {
        title: '坐标位置',
        key: 'address'
      },
      ],
      trackData: [],
      trackTotalPage: 0,
      trackPageNum: 1,
      trackPageSize: 20,
      alarmColumns: [
        {
          title: '序号',
          type: 'index',
          width: 60,
          align: 'center'
        }, {
          title: '报警名称',
          key: 'typeName'
        }, {
          title: '报警时间',
          key: 'time'
        }, {
          title: '报警持续时间',
          key: 'content'
        },
        // {
        //   title: '描述',
        //   key: 'message'
        // },
      ],
      alarmData: [],
      warningColumns: [
        {
          title: '序号',
          type: 'index',
          width: 60,
          align: 'center'
        }, {
          title: '违规名称',
          key: 'typeName'
        }, {
          title: '违规时间',
          key: 'time'
        }, {
          title: '违规持续时间',
          key: 'content'
        },
        //  {
        //   title: '描述',
        //   key: 'message'
        // },
      ],
      warningData: [],
      pointtimestamp: '',
      pointlatlon: '',
      resultdate: [],
      locationData: {},
      trackOldData: [],
      hours: 0,
      minute: 0,
      classs: '',
      carMarkerArr: [],
      personMarkerArr: [],
      pathCheckbox: ["run", "alarm", "work", "getout"],
      oldPathCheckbox: ["run", "alarm", "work", "getout"],
      marker: [],
      markerMessage: [],
      markerGrid: [],
      markerPath: [],
      markerCorrectPath: [],
      jobGrid: false,
      fence: false,
      carGrid: [],
      binArr: [],
      binData: [],
      binArrDone: [],
      isDrivingTrackShow: true,
      isAnimation: false,
      correctTrack: 'original',
      isStatusShow: false,
      messageTypeId: '',
      dateList: [],     // 三天日期的列表
      // trackTableSelect: 0,
      trackTableStartTime: '',
      trackTableEndTime: '',
      echartsData: [],
      globalData: [],
      globalRunPoints: [],
      globalWorkPoints: [],
      globalWarningPoints: [],
      globalAlarmPoints: [],
      globalData2: [],
      isWorking: false,
      getMileageAndOilWearOk: false,
      getCarLocationOk: false,
      getEmpLocationOk: false,
      dateListCursor: 0,
      mengVisible: false,
      drawDate: '',
      polygon: null
    };
  },
  watch: {},
  computed: {
    ...mapGetters({
      'indexName': 'getIndexName',
      'mapCenterLnglat': 'getMapCenterLnglat',
    }),
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      this.createMap()
      this.oldType = this.$route.query.type;
      this.name = this.$route.query.name;
      let str = this.$route.query.name.split('[');
      this.nameText = str[0]
      this.id = this.$route.query.id;
      this.carType = this.$route.query.carType;
      this.getCarWorkType()
    },
    // 获取车辆作业类型
    getCarWorkType () {
      this.$http.getCarWorkType({ id: this.id }).then(res => {
        if (res.code == 200) {
          const carType = res.result.jobType
          bus.$emit('carJobType', carType)
        }
      })
    },
    // 地图渲染
    createMap () {
      this.$Spin.show()
      tMap.init().then((T) => {
        this.$Spin.hide();
        this.T = T
        this.zoom = 15
        this.map = new T.Map('mapDiv')
        let lnglat
        if (this.mapCenterLnglat) {
          let mapCenterLnglat = this.mapCenterLnglat.split(',')
          lnglat = new T.LngLat(mapCenterLnglat[0], mapCenterLnglat[1])
        } else {
          lnglat = new T.LngLat(119.130974, 36.706688)
        }
        this.map.centerAndZoom(lnglat, this.zoom)
        let today = this.getLastOrNextFewDateBy(0);
        this.dateInterval = [today + ' 00:00:00', today + ' 23:59:59'];
        this.getTrack();
      }).catch(err => {
        this.$Message.info('地图加载失败,请刷新浏览器')
      })
    },
    // 轨迹节点获取
    getTrack () {
      this.loading = true
      this.map.clearOverLays();
      this.slider = 0
      if (this._CarTrack != undefined && this._CarTrack != null) {
        this._CarTrack.clear();
      }
      // if (this.oldType == 'car') {

      // } else if (this.oldType == 'person') {

      // }
      let t;
      let start = Math.floor((new Date(this.dateInterval[0]).getTime() + 8 * 1000 * 3600) / 1000 / 86400) * 1000 * 86400 - 8 * 1000 * 3600
      let end = Math.floor((new Date(this.dateInterval[1]).getTime() + 8 * 1000 * 3600) / 1000 / 86400) * 1000 * 86400 - 8 * 1000 * 3600
      let i = start
      this.dateList = []
      while (i <= end) {
        let d = { s: i, e: i + 1000 * 86400 - 1000, sf: formatDate(new Date(i)) + ' ' + formatTime(new Date(i)), ef: formatDate(new Date(i + 1000 * 86400 - 1000)) + ' ' + formatTime(new Date(i + 1000 * 86400 - 1000)), date: formatDate(new Date(i + 1000 * 86400 - 1000)) }
        this.dateList.push(d)
        i += 1000 * 86400
      }
      this.dateList[0].s = new Date(this.dateInterval[0]).getTime()
      this.dateList[0].sf = formatDate(new Date(this.dateInterval[0])) + ' ' + formatTime(new Date(this.dateInterval[0]))
      this.dateList[this.dateList.length - 1].e = new Date(this.dateInterval[1]).getTime() - 1000
      this.dateList[this.dateList.length - 1].ef = formatDate(new Date(this.dateInterval[1])) + ' ' + formatTime(new Date(this.dateInterval[1]))
      this.trackTableStartTime = this.dateList[0].sf
      this.trackTableEndTime = this.dateList[0].ef
      this.dateListCursor = 0

      this.globalData = []
      this.globalRunPoints = []
      this.globalWorkPoints = []
      this.globalWarningPoints = []
      this.globalAlarmPoints = []
      this.globalData2 = []
      if (this.markerGrid.length > 0) {
        this.markerGrid.forEach(item => {
          this.map.removeOverLay(item)
        })
      }
      this.markerGrid = []
      if (this.oldType == 'car') {
        // 车辆网格
        this.carGrid = []
        // 车辆总油耗总里程
        this.mileage = 0
        this.oilMassTotal = 0
        this.emptyMileage = 0
        this.homeworkMileage = 0
        this.oilMassHundred = 0
        bus.$emit('resetTrackCarMessageData')
        bus.$emit('resetBinList')
        this.alarmData = []
        this.warningData = []
        this.echartsData = []
        t = setInterval(() => {
          if (!this.isWorking) {
            if (this.dateList.length > 1) {
              this.mengVisible = true
              this.drawDate = this.dateList[this.dateListCursor].sf.split(' ')[0]
            }
            this.getMileageAndOilWearOk = false;
            this.getCarLocationOk = false;
            // 车辆总油耗总里程
            this.getMileageAndOilWear(this.dateList[this.dateListCursor].sf, this.dateList[this.dateListCursor].ef)
            // 原始轨迹
            if (this.correctTrack == 'original') {
              this.getCarLocation(this.dateList[this.dateListCursor].sf, this.dateList[this.dateListCursor].ef, 1, this.dateListCursor == 0, this.dateListCursor == this.dateList.length - 1)
            } else {
              // 纠偏轨迹
              this.getCarCorrectTrack()
            }
            this.isWorking = true;
          } else {
            if (this.getMileageAndOilWearOk && this.getCarLocationOk) {
              this.isWorking = false;
              this.dateListCursor++;
              if (this.dateListCursor == this.dateList.length) {
                clearInterval(t);
                this.mengVisible = false
              }
            }
          }
        }, 100);

        // // 获取车辆历史轨迹列表分页

        this.getCarTrackNodeListPage()
        // 车辆网格
        this.getCarTrackGrid(this.trackTableStartTime, this.trackTableEndTime)
        // 收集点列表
        this.getBinList(this.trackTableStartTime, this.trackTableEndTime)
        this.getCarLocation(this.trackTableStartTime, this.trackTableEndTime, 2, false, false)
      } else if (this.oldType == 'person') {
        this.speedArr = [
          {
            id: 1000,
            name: '1x'
          },
          {
            id: 200,
            name: '5x'
          },
          {
            id: 100,
            name: '10x'
          }, {
            id: 50,
            name: '20x'
          }
        ]

        t = setInterval(() => {
          if (!this.isWorking) {
            if (this.dateList.length > 1) {
              this.mengVisible = true
              this.drawDate = this.dateList[this.dateListCursor].sf.split(' ')[0]
            }
            this.getEmpLocationOk = false;
            this.getEmpLocation(this.dateList[this.dateListCursor].sf, this.dateList[this.dateListCursor].ef, 1, this.dateListCursor == 0, this.dateListCursor == this.dateList.length - 1)
            this.isWorking = true;
          } else {
            if (this.getEmpLocationOk) {
              this.isWorking = false;
              this.dateListCursor++;
              if (this.dateListCursor == this.dateList.length) {
                clearInterval(t);
                this.mengVisible = false
              }
            }
          }
        }, 100);
        this.getEmpLocation(this.trackTableStartTime, this.trackTableEndTime, 2, false, false)
        this.getPersonTrackGrid(this.trackTableStartTime, this.trackTableEndTime)
        this.getPersonTrackNodeListPage()
      }
    },
    // 轨迹表格选择器回调
    onChangeTrackTableData (value) {
      this.trackTableStartTime = this.dateList[value].sf
      this.trackTableEndTime = this.dateList[value].ef
      this.markerGrid.forEach(item => {
        this.map.removeOverLay(item)
      })
      if (this.oldType == 'car') {
        // 获取车辆历史轨迹列表分页
        this.getCarTrackNodeListPage()
        // 车辆网格
        this.getCarTrackGrid(this.trackTableStartTime, this.trackTableEndTime)
        // 收集点列表
        this.getBinList(this.trackTableStartTime, this.trackTableEndTime)
        this.getCarLocation(this.trackTableStartTime, this.trackTableEndTime, 2, false, false)
      } else if (this.oldType == 'person') {
        this.getPersonTrackGrid(this.trackTableStartTime, this.trackTableEndTime)
        this.getPersonTrackNodeListPage()
        this.getEmpLocation(this.trackTableStartTime, this.trackTableEndTime, 2, false, false)
      }
    },
    // 获取查询轨迹数据
    getInquireTrack () {
      this.getTrack()
      // 车辆总油耗总里程
      // this.getMileageAndOilWear()
    },
    // 获取车辆历史轨迹 
    async getCarLocation (start, end, type, isFirst, isLast) {
      // type 1是查询轨迹，2是油耗曲线等
      // this.carMarkerArr = []
      this.marker = []
      this.markerMessage = []
      if (type == 2) {
        this.slOptions.series[0].data = []
        this.slOptions.series[1].data = []
        this.slOptions.xAxis.data = []
      }
      let params = {
        carId: this.id,
        starttime: start,
        endtime: end
      }
      if (type == 1 && this.dateList.length > 1) {
        params.simplify = 1
      }
      let res = await this.$http.getAllCarTrackNodeList(params)
      // isDanger
      // isWarning
      // lastMessageCheck
      // duration
      // timestamps
      // warningStay
      // isStay
      // messageType
      // message
      // warningName
      // oilMass
      // speed
      // timestamp
      if (res.code == 200) {
        this.getCarLocationOk = true
        let data = res.result.carTrackList
        let data2 = this.getData2(data)     // 轨迹数组
        if (type == 1) {
          this.allTimeList = data.filter((item, index) => {
            if (index != data.length - 1) {   // 不是最后一项
              if (item.isDanger == 1 || item.isWarning == 1) {
                if (item.isDanger != data[index + 1].isDanger || item.isWarning != data[index + 1].isWarning) {
                  if (item.lastMessageCheck) {
                    for (const key in item) {
                      if (item.lastMessageCheck == key) {
                        item.duration = this.$timeSecondsTransition(item[key])
                      }
                    }
                  }
                  item.duration = item.duration ? item.duration : 0
                  item.timestamps = item.timestamp.substring(10)
                  if (item.warningStay != 0) {
                    item.isDanger = 0
                    item.isStay = 1
                    item.messageType = 3
                  } else {
                    if (item.isDanger == 1) {
                      let name = item.message.split(':')
                      item.dangerName = name[0]
                      item.messageType = 1
                    } else {
                      let name = item.message.split(':')
                      item.warningName = name[0]
                      item.messageType = 2
                    }
                  }
                  this.carMarkerArr.push(item)
                  this.drawMarker(item)
                }
              }
            }
          })
          this.locationData = res
          this.drawTrackCar(this.locationData, isFirst, isLast);
        } else {
          this.getDinData()
          bus.$emit('trackCarMessageData', res.result)
          // 报警数据 升序 日期旧的在前边
          this.alarmData = res.result.alarmCarList.reverse()
          // this.alarmData = this.alarmData.sort(function (a, b) {
          //   return new Date(a.time).getTime() - new Date(b.time).getTime()
          // })
          // 违规数据  升序 日期旧的在前边
          this.warningData = res.result.carViolationList.reverse()
          // this.warningData = this.warningData.sort(function (a, b) {
          //   return new Date(a.time).getTime() - new Date(b.time).getTime()
          // })
          this.trackOldData = data
          // this.trackOldData = {
          //   isWarning: data.isWarning,
          //   lastMessageCheck: data.lastMessageCheck,
          //   duration: data.duration,
          //   timestamps: data.timestamps,
          //   warningStay: data.warningStay,
          //   isStay: data.isStay,
          //   messageType: data.messageType,
          //   message: data.message,
          //   warningName: data.warningName,
          //   oilMass: data.oilMass,
          //   speed: data.speed,
          //   timestamp: data.timestamp,
          // }
          let echartsData = []
          data.forEach((item, index) => {
            let obj = {
              oilNum: item.oilMass,
              slArr: item.speed,
              date: item.timestamp
            }
            echartsData.push(obj)
          })
          // 图表数据  升序 旧的日期在前边
          this.echartsData = echartsData
          // this.echartsData = this.echartsData.sort(function (a, b) {
          //   return new Date(a.date).getTime() - new Date(b.date).getTime()
          // })
          // 图表
          this.slOptions.series[0].data = this.echartsData.map(item => item.slArr)
          this.slOptions.series[1].data = this.echartsData.map(item => item.oilNum)
          this.slOptions.xAxis.data = this.echartsData.map(item => item.date)
          let numMax = this.slOptions.series[1].data.some(item => item > 120)
          let numMax1 = this.slOptions.series[1].data.some(item => item > 240)
          let numMax2 = this.slOptions.series[1].data.some(item => item > 400)
          if (numMax2) {
            this.slOptions.yAxis.max = 600
          } else if (numMax1) {
            this.slOptions.yAxis.max = 400
          } else if (numMax) {
            this.slOptions.yAxis.max = 240
          } else {
            this.slOptions.yAxis.max = 120
          }
          //this.$refs.oilRef.init()
        }
      }
    },
    getData2 (data) {
      data = this.formatData(data)
      let data2 = []
      let x
      if (this.dateList.length > 5) {
        x = 1
      } else {
        x = 5
      }
      data.forEach((item, index) => {
        if (index == 0) {
          data2.push(item)
        } else {
          let speedNum = 0
          let speedNum1 = 0
          let oilNum1 = 0
          let oilNum2 = 0
          for (let i = 1; i < x; i++) {
            let data3 = new Object()
            data3.eqpnum = item.eqpnum
            data3.lat = parseFloat(parseFloat(data[index - 1].lat) + (parseFloat(data[index].lat) - parseFloat(data[index - 1].lat)) * i / x) + ''
            data3.lng = parseFloat(parseFloat(data[index - 1].lng) + (parseFloat(data[index].lng) - parseFloat(data[index - 1].lng)) * i / x) + ''
            data3.location = data3.lat + ',' + data3.lng
            speedNum = parseFloat(this.$subtraction(data[index].speed, data[index - 1].speed))
            speedNum1 = parseFloat(this.$addition(data[index - 1].speed, speedNum))
            data3.speed = parseFloat(speedNum1)
            oilNum1 = parseFloat(this.$subtraction(data[index].oilMass, data[index - 1].oilMass))
            oilNum2 = parseFloat(this.$addition(data[index - 1].oilMass, oilNum1))
            data3.timestamp = this.$times(new Date(data[index - 1].timestamp).getTime() + (new Date(data[index].timestamp).getTime() - new Date(data[index - 1].timestamp).getTime()) * i / x)
            data3.oilMass = parseFloat(oilNum2)
            data3.isDanger = data[index].isDanger
            data3.isWarning = data[index].isWarning
            data3.address = data[index].address
            data3.status = data[index].status
            data3.facilityId = data[index].facilityId
            data3.isWorkIng = data[index].isWorkIng
            data2.push(data3)
          }
          data2.push(item)
        }
      })
      this.getBinIsDone(data2)
      return data2
    },
    // 数据格式化
    formatData (data) {
      // data = data.reverse()

      data.forEach((item, index) => {
        data[index].lat = parseFloat(data[index].lat)
        data[index].lng = parseFloat(data[index].lng)
      });
      let data2 = []
      data.forEach((item, index) => {
        if (index == 0 || data[index].timestamp != data[index - 1].timestamp) {
          data2.push(data[index])
        }
      });
      data = data2
      let diff = 0
      let s = 0.0006
      let y = 0.0000001
      let k = 0
      let kMax = 99999999
      data.forEach((item, index) => {
        if (index > 1) {
          diff = this.getDiff(data[index].lat, data[index].lng, data[index - 1].lat, data[index - 1].lng)
          if (diff < s) {
            if ((data[index - 1].lng - data[index - 2].lng) == 0) {
              k = kMax
            } else {
              k = (data[index - 1].lat - data[index - 2].lat) / (data[index - 1].lng - data[index - 2].lng)
            }
            if (k == kMax) {
              data[index].lat = data[index - 1].lat + y
              data[index].lng = data[index - 1].lng
            } else {
              data[index].lat = data[index - 1].lat + k * y
              data[index].lng = data[index - 1].lng + y
            }
          }
        }
      });
      data.forEach((item, index) => {
        if (index > 1) {
          diff = this.getDiff(data[index].lat, data[index].lng, data[index - 1].lat, data[index - 1].lng)
        }
      });
      return data
    },
    // 计算两点距离简易版
    getDiff (lat1, lng1, lat2, lng2) {
      return Math.sqrt(Math.pow((lat1 - lat2), 2) + Math.pow((lng1 - lng2), 2))
    },
    // 获取车辆纠偏后轨迹
    getCarCorrectTrack () {
      let params = {
        carId: this.id,
        starttime: this.dateInterval[0],
        endtime: this.dateInterval[1]
      }
      this.marker = []
      this.$http.getCarCorrectTrack({
        carId: this.id, starttime: this.dateInterval[0], endtime: this.dateInterval[1]
      }).then(res => {
        if (res.code == 200) {
          this.getCarLocationOk = true
          if (res.result.carTrackList.length > 0) {
            let data = res.result.carTrackList
            this.trackOldData = data
            let slArr = []
            let oilNum = []
            let date = []
            data.forEach((item, index) => {
              oilNum.push(item.oilMass)
              slArr.push(item.speed)
              date.push(item.timestamp)
            })
            let data2 = this.getData2(data)
            this.locationData = res
            this.drawTrackCar(this.locationData);
            this.getDinData()
          } else {
            this.$Message.info('没有纠偏轨迹')
          }
        }
      })
    },
    // 垃圾桶是否完成
    getBinIsDone (data) {
      let obj = {}
      data.forEach((item, index) => {
        if (item.facilityId != null) {
          obj[index] = item.facilityId
        }
      });
      let newArr = []
      for (const key in obj) {
        let arr = obj[key].split(',')
        let newObj = {}
        arr.forEach((item, index) => {
          newObj = {}
          newObj.index = key
          newObj.id = item
          newArr.push(newObj)
        })
      }
      const res = new Map();
      const dataArr = newArr.filter((a) => !res.has(a.id) && res.set(a.id, 1))
      this.binArrDone = dataArr //.length > this.binArrDone.length ? dataArr : this.binArrDone
      return dataArr
    },
    // 获取车辆历史轨迹列表分页
    getCarTrackNodeListPage () {
      let params = {
        page: this.trackPageNum,
        size: this.trackPageSize,
        carId: this.id,
        starttime: this.trackTableStartTime,
        endtime: this.trackTableEndTime,
      }
      this.$http.getCarTrackNodeListPage(params).then((res) => {
        let data = res.result
        // if (data.total > 0) {
        this.trackData = data.list
        this.trackTotalPage = data.total
        // }
      })
    },
    // 获取车辆网格数据
    getCarTrackGrid (start, end) {
      this.jobGrid = false
      let params = {
        carId: this.id,
        startDate: start.split(' ')[0],
        endDate: end.split(' ')[0],
      }
      this.$http.getCarTrackGrid(params).then((res) => {
        if (res.code == 200) {
          this.getCarTrackGridOk = true;
          let data = res.result
          // this.carGrid = this.carGrid.concat(data)
          this.carGrid = data
          if (data.length != 0) {
            this.jobGrid = true
          }
          data.forEach(item => {
            this.drawGrid(item)
          })
          // console.log(this.carGrid.length)
        }
      })
    },
    // 获取收集点列表
    getBinList (start, end) {
      let params = {
        carId: this.id,
        starttime: start,
        endtime: end,
      }
      this.$http.getBinList(params).then((res) => {
        if (res.code == 200) {
          bus.$emit('binList', res.result)
          this.getBinListOk = true;
        }
      })
    },
    // 车辆轨迹渲染
    async drawTrackCar (res, isFirst, isLast) {
      this.resultdate = res.result;
      let data = res.result.carTrackList;
      this.globalData = this.globalData.concat(data);
      if (this.globalData.length > 0) {
        this.loading = false
        if (isFirst) {
          this.pointtimestamp = this.globalData[0].timestamp
          this.pointlatlon = this.globalData[0].address
        }
        this.map.panTo(new T.LngLat(this.globalData[0].lng, this.globalData[0].lat));
        if (isFirst) {
          this.addMarker(require('../../assets/main/point-start2.png'), this.globalData[0].lng, this.globalData[0].lat);
        }
        if (isLast) {
          this.addMarker(require('../../assets/main/point-end2.png'), this.globalData[this.globalData.length - 1].lng, this.globalData[this.globalData.length - 1].lat);
        }
        this.track(this.globalData, 'car', '', isFirst, isLast)
      } else {
        this.loading = false
        this.$Message.info('没有轨迹数据')
      }
    },
    // 获取人员历史轨迹
    getEmpLocation (start, end, type, isFirst, isLast) {
      this.ersonMarkerArr = []
      this.marker = []
      this.$http.getAllPersonTrackNodeList({
        employeeId: this.id, starttime: start, endtime: end
      }).then((res) => {
        if (res.code == 200) {
          if (type == 2) {
            bus.$emit('trackPersonMessageData', res.result)
            this.alarmData = res.result.alarmEmployeeList.reverse()
            this.warningData = res.result.employeeViolationList.reverse()
          } else if (type == 1) {
            let data = JSON.parse(JSON.stringify(res.result.employeeTrackList))
            let data2 = []
            let x
            if (this.dateList.length > 5) {
              x = 1
            } else {
              x = 5
            }
            data.forEach((item, index) => {
              if (index == 0) {
                data2.push(item)
              } else {
                for (let i = 1; i < x; i++) {
                  let data3 = new Object()
                  data3.eqpnum = item.eqpnum
                  data3.lat = parseFloat(data[index - 1].lat + (data[index].lat - data[index - 1].lat) * index / x) + ''
                  data3.lng = parseFloat(data[index - 1].lng + (data[index].lng - data[index - 1].lng) * index / x) + ''
                  data3.location = data3.lat + ',' + data3.lng
                  data3.timestamp = item.timestamp
                  data3.createTime = item.createTime
                  data3.messageType = data[index].messageType
                  data3.isDanger = data[index].isDanger
                  data3.isWarning = data[index].isWarning
                  data3.address = data[index].address
                  data2.push(data3)
                }
                data2.push(item)
              }
            })
            data.forEach((item, index) => {
              if (index != data.length - 1) {
                if (item.isDanger != 0 || item.isWarning != 0) {
                  if (item.isDanger != data[index + 1].isDanger || item.isWarning != data[index + 1].isWarning) {
                    if (item.lastMessageCheck) {
                      for (const key in item) {
                        if (item.lastMessageCheck == key) {
                          item.duration = this.$timeSecondsTransition(item[key])
                        }
                      }
                    }
                    item.duration = item.duration ? item.duration : ''
                    item.timestamps = item.timestamp.substring(10)
                    if (item.isDanger == 1) {
                      let name = item.message.split(':')
                      item.dangerName = name[0]
                      item.messageType = 1
                    } else {
                      let name = item.message.split(':')
                      item.warningName = name[0]
                      item.messageType = 2
                    }
                    this.personMarkerArr.push(item)
                    this.drawMarker(item)
                  }
                }
              } else {
                if (item.isDanger != 0 || item.isWarning != 0) {
                  if (item.lastMessageCheck) {
                    for (const key in item) {
                      if (item.lastMessageCheck == key) {
                        item.duration = this.$timeSecondsTransition(item[key])
                      }
                    }
                  }
                  item.duration = item.duration ? item.duration : '--'
                  item.timestamps = item.timestamp.substring(10)
                  if (item.isDanger == 1) {
                    item.messageType = 1
                  } else {
                    item.messageType = 2
                  }
                  this.personMarkerArr.push(item)
                  this.drawMarker(item)
                }
              }
            })

            res.result.employeeTrackList = data2
            this.locationData = res
            this.drawTrackPerson(this.locationData, isFirst, isLast)
            this.getEmpLocationOk = true
          }
        }
      })
    },
    // 获取人员历史轨迹列表分页
    getPersonTrackNodeListPage () {
      let params = {
        page: this.trackPageNum,
        size: this.trackPageSize,
        employeeId: this.id,
        starttime: this.trackTableStartTime,
        endtime: this.trackTableEndTime,
      }
      this.$http.getPersonTrackNodeListPage(params).then((res) => {
        let data = res.result
        let list
        if (data.total > 0) {
          list = data.list
          // list.forEach(item => {
          //   item.timestamp = item.timestamp
          // });
          this.trackData = list
          this.trackTotalPage = data.total
        }
      })
    },
    // 获取人员网格数据
    getPersonTrackGrid (start, end) {
      this.jobGrid = false
      let params = {
        employeeId: this.id,
        starttime: start,
        endtime: end,
      }
      this.$http.getPersonTrackGrid(params).then((res) => {
        if (res.code == 200) {
          let data = res.result
          if (data.length != 0) {
            this.jobGrid = true
          }
          data.forEach(item => {
            this.drawGrid(item)
          })
        }
      })
    },
    // 人员轨迹渲染
    async drawTrackPerson (res, isFirst, isLast) {
      let data = res.result.employeeTrackList;
      this.resultdate = res.result;
      console.log("s=" + this.globalData.length)
      this.globalData = this.globalData.concat(data);
      console.log("e=" + this.globalData.length)
      if (data.length > 0) {
        if (isFirst) {
          this.pointtimestamp = data[0].timestamp;
          this.pointlatlon = data[0].address
        }
        this.map.panTo(new T.LngLat(data[0].lng, data[0].lat));
        if (isFirst) {
          this.addMarker(require('../../assets/main/point-start2.png'), data[0].lng, data[0].lat);
        }
        if (isLast) {
          this.addMarker(require('../../assets/main/point-end2.png'), data[data.length - 1].lng, data[data.length - 1].lat);
        }

        this.track(this.globalData, 'person', '', isFirst, isLast)
      } else {
        this.$Message.info('没有轨迹数据')
      }
    },
    // 车辆总油耗总里程
    getMileageAndOilWear (start, end) {
      let moment = require('moment');
      let params = {
        carId: this.id,
        starttime: start,
        endtime: end
      }
      this.$http.getMileageAndOilWear(params).then(res => {
        if (res.code == 200) {
          let data = res.result
          this.mileage = parseFloat(this.mileage) + data.totalMileage
          this.oilMassTotal = parseFloat(this.oilMassTotal) + data.oilWear
          this.emptyMileage = parseFloat(this.emptyMileage) + data.emptyMileage
          this.homeworkMileage = parseFloat(this.homeworkMileage) + data.jobMileage
          if (this.mileage >= 0.5) {
            let flag = this.oilMassTotal > 0 && this.mileage > 0
            let num = this.oilMassTotal / this.mileage * 100
            this.oilMassHundred = flag ? parseFloat(num.toFixed(2)) : 0
          } else {
            this.oilMassHundred = '- -'
          }
          this.getMileageAndOilWearOk = true
        }
      })
    },
    // 变速
    async setSpeed (speed) {
      this.onClickMap()
      this.slider2 = this.slider
      this._CarTrack.pause();
      this.pause = false;
      let data = this.oldType == 'car' ? this.globalData : this.locationData.result.employeeTrackList
      let num = this.oldType == 'car' ? parseInt(this.globalData.length * this.slider / 100) : parseInt(this.locationData.result.employeeTrackList.length * this.slider / 100)
      let data2 = []
      data.forEach((item, index) => {
        if (index >= num) {
          data2.push(item)
        }
      })
      this.getBinIsDone(data2)
      this.pointlatlon = data2[0].address;
      this.pointtimestamp = data2[0].timestamp
      this._CarTrack.clear()
      if (this.oldType == 'car') {
        this.track(data2, 'car', 'progress', true, true, true)
      } else {
        this.track(data2, 'person', 'progress', true, true, true)
      }
    },
    // 开始/暂停
    trackStart () {
      this.onClickMap()
      if (!this.pause) {
        if (this.globalData.length == 0) {
          this.$Message.info('没有轨迹数据')
        } else {
          this._CarTrack.start();
          this.pause = true;
        }
      } else {
        this._CarTrack.pause();
        this.pause = false;
      }
    },
    // 进度条
    async sliderData (i) {
      this.onClickMap()
      this._CarTrack.pause();
      this.pause = false;
      this.slider = i
      this.slider2 = i
      let data = this.globalData
      let num = parseInt(this.globalData.length * this.slider / 100)
      let data2 = []
      let data3 = []
      data.forEach((item, index) => {
        if (index >= num) {
          data2.push(item)
        } else {
          data3.push(item)
        }
      })
      if (this.isAnimation) {
        if (this.oldType == 'car') {
          this.binArr.forEach(item => {
            item.isClean = 0
            this.drawMarker(item)
          })
          let binArr = this.getBinIsDone(data3)
          data3.forEach((item, index) => {
            binArr.forEach(items => {
              if (items.index == index) {
                this.binArr.forEach(itemss => {
                  if (itemss.id == items.id) {
                    itemss.isClean = 1
                    this.drawMarker(itemss)
                  }
                })
              }
            })
          })
        }
      }
      this.getBinIsDone(data2)
      this.pointlatlon = data2[0].address
      this.pointtimestamp = data2[0].timestamp
      this._CarTrack.clear()
      if (this.oldType == 'car') {
        this.track(data2, 'car', 'progress', true, true, true)
      } else {
        this.track(data2, 'person', 'progress', true, true, true)
      }
    },
    // 轨迹封装
    async track (data, type, manifestation, isFirst, isLast, isProcess = false) {
      // if (this._CarTrack != undefined) {
      //   this._CarTrack.clear()
      //   this._CarTrack = new T.CarTrack(this.map, {
      //     carstyle: {
      //       display: true,
      //       iconUrl: require('../../assets/main/marker_car1.png'),
      //       width: 52,
      //       height: 26
      //     },
      //   })
      // }
      // if (this.markerPath.length > 0) {
      //   this.markerPath.forEach(item => {
      //     item.show()
      //   });
      //   if (this.markerCorrectPath.length > 0) {
      //     this.markerCorrectPath.forEach(item => {
      //       item.hide()
      //     })
      //   }
      // } else {
      let workPoints = [];
      let workPointsItem = [];
      let runPoints = [];
      let runPointsItem = [];
      let alarmPoints = [];
      let alarmPointsItem = [];
      let warningPoints = [];
      let warningPointsItem = [];
      let points = [];
      let that = this;
      let binArrDone = this.binArrDone
      let oldStatus = 0;
      let oldIsDanger = 0;
      let oldIsWarning = 0;
      let data3 = [];
      if (isProcess) {
        that.globalRunPoints = []
        that.globalWarningPoints = []
        that.globalAlarmPoints = []
        that.globalWorkPoints = []
      }
      if (type == 'car') {
        let data2 = data.map(function (obj, i) {
          let lnlat = new T.LngLat(obj.lng, obj.lat);
          if (obj.isWorkIng == 1) {
            if (oldStatus != 1) {
              if (i > 0) {
                let lnlat2 = new T.LngLat(data[i - 1].lng, data[i - 1].lat);
                runPointsItem.push(lnlat2);
                lnlat2 = new T.LngLat(data[i].lng, data[i].lat);
                runPointsItem.push(lnlat2);
                runPoints.push(JSON.parse(JSON.stringify(runPointsItem)));
                that.globalRunPoints.push(JSON.parse(JSON.stringify(runPointsItem)));
                runPointsItem = [];
              }
            }
            workPointsItem.push(lnlat);
            if (runPointsItem.length > 0) {
              runPoints.push(JSON.parse(JSON.stringify(runPointsItem)));
              that.globalRunPoints.push(JSON.parse(JSON.stringify(runPointsItem)));
              runPointsItem = [];
            }
            if (warningPointsItem.length > 0) {
              warningPoints.push(JSON.parse(JSON.stringify(warningPointsItem)));
              that.globalWarningPoints.push(JSON.parse(JSON.stringify(warningPointsItem)));
              warningPointsItem = [];
            }
            if (alarmPointsItem.length > 0) {
              alarmPoints.push(JSON.parse(JSON.stringify(alarmPointsItem)));
              that.globalAlarmPoints.push(JSON.parse(JSON.stringify(alarmPointsItem)));
              alarmPointsItem = [];
            }
          } else {
            if (obj.isDanger == 1) {
              if (oldIsDanger != 1) {
                if (i > 0) {
                  let lnlat2 = new T.LngLat(data[i - 1].lng, data[i - 1].lat);
                  runPointsItem.push(lnlat2);
                  lnlat2 = new T.LngLat(data[i].lng, data[i].lat);
                  runPointsItem.push(lnlat2);
                  runPoints.push(JSON.parse(JSON.stringify(runPointsItem)));
                  that.glabalRunPoints.push(JSON.parse(JSON.stringify(runPointsItem)));
                  runPointsItem = [];
                }
              }
              alarmPointsItem.push(lnlat)
              if (warningPointsItem.length > 0) {
                warningPoints.push(JSON.parse(JSON.stringify(warningPointsItem)));
                that.globalWarningPoints.push(JSON.parse(JSON.stringify(warningPointsItem)));
                warningPointsItem = [];
              }
              if (runPointsItem.length > 0) {
                runPoints.push(JSON.parse(JSON.stringify(runPointsItem)));
                that.globalRunPoints.push(JSON.parse(JSON.stringify(runPointsItem)));
                runPointsItem = [];
              }
              if (workPointsItem.length > 0) {
                workPoints.push(JSON.parse(JSON.stringify(workPointsItem)));
                that.globalWorkPoints.push(JSON.parse(JSON.stringify(workPointsItem)));
                workPointsItem = [];
              }
            }
            else if (obj.isWarning == 1) {
              if (oldIsWarning != 1) {
                if (i > 0) {
                  let lnlat2 = new T.LngLat(data[i - 1].lng, data[i - 1].lat);
                  runPointsItem.push(lnlat2);
                  lnlat2 = new T.LngLat(data[i].lng, data[i].lat);
                  runPointsItem.push(lnlat2);
                  runPoints.push(JSON.parse(JSON.stringify(runPointsItem)));
                  that.globalRunPoints.push(JSON.parse(JSON.stringify(runPointsItem)));
                  runPointsItem = [];
                }
              }
              warningPointsItem.push(lnlat)
              if (alarmPointsItem.length > 0) {
                alarmPoints.push(JSON.parse(JSON.stringify(alarmPointsItem)));
                that.globalAlarmPoints.push(JSON.parse(JSON.stringify(alarmPointsItem)));
                alarmPointsItem = [];
              }
              if (runPointsItem.length > 0) {
                runPoints.push(JSON.parse(JSON.stringify(runPointsItem)));
                that.globalRunPoints.push(JSON.parse(JSON.stringify(runPointsItem)));
                runPointsItem = [];
              }
              if (workPointsItem.length > 0) {
                workPoints.push(JSON.parse(JSON.stringify(workPointsItem)));
                that.globalWorkPoints.push(JSON.parse(JSON.stringify(workPointsItem)));
                workPointsItem = [];
              }
            } else {
              if (oldIsWarning == 1 || oldIsDanger == 1 || oldStatus == 1) {
                if (i > 0) {
                  let lnlat2 = new T.LngLat(data[i - 1].lng, data[i - 1].lat);
                  runPointsItem.push(lnlat2);
                }
              }
              runPointsItem.push(lnlat);
              if (workPointsItem.length > 0) {
                workPoints.push(JSON.parse(JSON.stringify(workPointsItem)));
                that.globalWorkPoints.push(JSON.parse(JSON.stringify(workPointsItem)));
                workPointsItem = [];
              }
              if (alarmPointsItem.length > 0) {
                alarmPoints.push(JSON.parse(JSON.stringify(alarmPointsItem)));
                that.globalAlarmPoints.push(JSON.parse(JSON.stringify(alarmPointsItem)));
                alarmPointsItem = [];
              }
              if (warningPointsItem.length > 0) {
                warningPoints.push(JSON.parse(JSON.stringify(warningPointsItem)));
                that.globalWarningPoints.push(JSON.parse(JSON.stringify(warningPointsItem)));
                warningPointsItem = [];
              }
            }
          }
          points.push(lnlat);
          oldIsDanger = obj.isDanger;
          oldIsWarning = obj.isWarning;
          oldStatus = obj.isWorkIng;
          return lnlat;
        })
        if (!isProcess) {
          this.globalData2 = this.globalData2.concat(data2);
          data3 = this.globalData2;
        } else {
          data3 = data
        }
        if (isLast) {
          this._CarTrack = new T.CarTrack(this.map, {
            interval: that.mSpeed,
            carstyle: {
              display: true,
              iconUrl: require('../../assets/main/marker_car1.png'),
              width: 52,
              height: 26
            },
            speed: 0,
            dynamicLine: true,
            Datas: data3,
            async passOneNode (lnglat, index, length) {
              if (that.isAnimation) {
                binArrDone.forEach(item => {
                  if (item.index == index) {
                    that.binArr.forEach(items => {
                      if (items.id == item.id) {
                        items.isClean = 1
                        that.drawMarker(items)
                      }
                    })
                  }
                });
              }
              let mPos;
              if (isProcess) {
                mPos = Math.floor((index * data.length) / length);
              } else {
                mPos = Math.floor((index * that.globalData2.length) / length);
              }

              if (manifestation == 'progress') {
                let sliderNum = parseInt(index / length * (100 - that.slider2)) + that.slider2
                that.slider = sliderNum > 100 ? 100 : sliderNum
              } else {
                that.slider = index / length * 100
              }
              if (mPos < that.globalData.length - 1 && mPos > 0) {
                if (isProcess) {
                  that.pointtimestamp = data[mPos].timestamp
                  that.pointlatlon = data[mPos].address;
                  that.oilMass = data[mPos].oilMass ? data[mPos].oilMass : 0
                  that.speed = data[mPos].speed ? data[mPos].speed : 0
                } else {
                  that.pointtimestamp = that.globalData[mPos].timestamp
                  that.pointlatlon = that.globalData[mPos].address;
                  that.oilMass = that.globalData[mPos].oilMass ? that.globalData[mPos].oilMass : 0
                  that.speed = that.globalData[mPos].speed ? that.globalData[mPos].speed : 0
                }

                let bs = that.map.getBounds();
                let bssw = bs.getSouthWest();
                let bsne = bs.getNorthEast();
                if (lnglat.lng < bssw.lng || lnglat.lng > bsne.lng || lnglat.lat < bssw.lat || lnglat.lat > bsne.lat) {
                  that.map.panTo(new T.LngLat(lnglat.lng, lnglat.lat));
                }
              }
              if (index == length) {
                that._CarTrack.stop();
                that.pause = false;
                that.slider = 0
                if (manifestation == 'progress') {
                  that._CarTrack.clear()
                  that.drawTrackCar(that.locationData)
                }
              }
            }
          })

        }
        if (runPointsItem.length > 0) {
          runPoints.push(JSON.parse(JSON.stringify(runPointsItem)));
          that.globalRunPoints.push(JSON.parse(JSON.stringify(runPointsItem)));
          runPointsItem = [];
        }
        if (workPointsItem.length > 0) {
          workPoints.push(JSON.parse(JSON.stringify(workPointsItem)));
          that.globalWorkPoints.push(JSON.parse(JSON.stringify(workPointsItem)));
          workPointsItem = [];
        }
        if (alarmPointsItem.length > 0) {
          alarmPoints.push(JSON.parse(JSON.stringify(alarmPointsItem)));
          that.globalAlarmPoints.push(JSON.parse(JSON.stringify(alarmPointsItem)));
          alarmPointsItem = [];
        }
        if (warningPointsItem.length > 0) {
          warningPoints.push(JSON.parse(JSON.stringify(warningPointsItem)));
          that.globalWarningPoints.push(JSON.parse(JSON.stringify(warningPointsItem)));
          warningPointsItem = [];
        }
        if (manifestation != 'progress') {
          //this.markerPath = []
        }
        if (manifestation != 'progress') {
          let icon = require('../../assets/main/ico_alarm.png')
          let iconObj = new T.Icon({
            iconUrl: icon
          });
          //创建线对象
          workPoints.forEach(item => {
            // 线
            let workLine = new T.Polyline(item, { color: '#7ed321', weight: 3, opacity: 0.8, type: 'work' });
            this.markerPath.push(workLine)
            this.map.addOverLay(workLine)
          })
          runPoints.forEach(item => {
            let runLine = new T.Polyline(item, { color: '#2283EB', weight: 3, opacity: 0.8, type: 'run' });
            // let runLine = new T.Polyline(item, { color: 'red', weight: 3, opacity: 0.8, type: 'run' });
            this.markerPath.push(runLine)
            this.map.addOverLay(runLine)

            // item.forEach(items => {
            //   // 点
            //   let marker = new T.Marker(new T.LngLat(items.lng, items.lat), { icon: iconObj });
            //   // console.log(marker);
            //   this.map.addOverLay(marker);
            // })
          })
          alarmPoints.forEach(item => {
            let alarm = new T.Polyline(item, { color: '#c62828', weight: 3, opacity: 0.8, type: 'alarm' });
            this.markerPath.push(alarm)
            this.map.addOverLay(alarm)
          })
          warningPoints.forEach(item => {
            let warning = new T.Polyline(item, { color: '#f5a623', weight: 3, opacity: 0.8, type: 'getout' });
            this.markerPath.push(warning)
            this.map.addOverLay(warning)
          })

        }


      } else {
        // people track start
        let data2 = data.map(function (obj, i) {
          let lnlat = new T.LngLat(obj.lng, obj.lat);
          if (obj.messageType == 1) {
            alarmPointsItem.push(lnlat)
            if (warningPointsItem.length > 0) {
              warningPoints.push(JSON.parse(JSON.stringify(warningPointsItem)));
              that.globalWarningPoints.push(JSON.parse(JSON.stringify(warningPointsItem)));
              warningPointsItem = [];
            }
            if (runPointsItem.length > 0) {
              runPoints.push(JSON.parse(JSON.stringify(runPointsItem)));
              that.globalRunPoints.push(JSON.parse(JSON.stringify(runPointsItem)));
              runPointsItem = [];
            }
          } else if (obj.messageType == 2) {
            warningPointsItem.push(lnlat)
            if (alarmPointsItem.length > 0) {
              alarmPoints.push(JSON.parse(JSON.stringify(alarmPointsItem)));
              that.globalAlarmPoints.push(JSON.parse(JSON.stringify(alarmPointsItem)));
              alarmPointsItem = [];
            }
            if (runPointsItem.length > 0) {
              runPoints.push(JSON.parse(JSON.stringify(runPointsItem)));
              that.globalRunPoints.push(JSON.parse(JSON.stringify(runPointsItem)));
              runPointsItem = [];
            }
          } else {
            runPointsItem.push(lnlat);
            if (alarmPointsItem.length > 0) {
              alarmPoints.push(JSON.parse(JSON.stringify(alarmPointsItem)));
              that.globalAlarmPoints.push(JSON.parse(JSON.stringify(alarmPointsItem)));
              alarmPointsItem = [];
            }
            if (warningPointsItem.length > 0) {
              warningPoints.push(JSON.parse(JSON.stringify(warningPointsItem)));
              that.globalWarningPoints.push(JSON.parse(JSON.stringify(warningPointsItem)));
              warningPointsItem = [];
            }
          }
          return lnlat;
        })
        this.globalData2 = this.globalData2.concat(data2);
        if (isLast) {
          this._CarTrack = new T.CarTrack(this.map, {
            interval: that.mSpeed,
            carstyle: {
              iconUrl: "https://hssfile.oss-cn-beijing.aliyuncs.com/file/people_in_449fcd105f8148fda5de850dc68fa634.jpg",
              zIndex: 999,
              width: 40,
              height: 42
            },
            speed: 0,
            dynamicLine: true,
            // polylinestyle: { color: "#2C64A7", weight: 5, opacity: 0.9 },
            Datas: data2,
            async passOneNode (lnglat, index, length) {
              let mPos;
              if (isProcess) {
                mPos = Math.floor((index * data.length) / length);
              } else {
                mPos = Math.floor((index * that.globalData2.length) / length);
              }
              if (manifestation == 'progress') {
                let sliderNum = parseInt(index / length * (100 - that.slider2)) + that.slider2
                that.slider = sliderNum > 100 ? 100 : sliderNum
              } else {
                that.slider = index / length * 100
              }
              if (mPos < that.globalData.length - 1 && mPos > 0) {
                if (isProcess) {
                  that.pointtimestamp = data[mPos].timestamp
                  that.pointlatlon = data[mPos].address;
                  that.oilMass = data[mPos].oilMass ? data[mPos].oilMass : 0
                  that.speed = data[mPos].speed ? data[mPos].speed : 0
                } else {
                  that.pointtimestamp = that.globalData[mPos].timestamp
                  that.pointlatlon = that.globalData[mPos].address;
                  that.oilMass = that.globalData[mPos].oilMass ? that.globalData[mPos].oilMass : 0
                  that.speed = that.globalData[mPos].speed ? that.globalData[mPos].speed : 0
                }

                let bs = that.map.getBounds();
                let bssw = bs.getSouthWest();
                let bsne = bs.getNorthEast();
                if (lnglat.lng < bssw.lng || lnglat.lng > bsne.lng || lnglat.lat < bssw.lat || lnglat.lat > bsne.lat) {
                  that.map.panTo(new T.LngLat(lnglat.lng, lnglat.lat));
                }
              }
              if (index == length) {
                that._CarTrack.stop();
                that.pause = false;
                that.slider = 0;
                if (manifestation == 'progress') {
                  that._CarTrack.clear()
                  that.drawTrackPerson(that.locationData)
                }
              }
            }
          })
        }
        if (runPointsItem.length > 0) {
          runPoints.push(JSON.parse(JSON.stringify(runPointsItem)));
          that.globalRunPoints.push(JSON.parse(JSON.stringify(runPointsItem)));
          runPointsItem = [];
        }
        if (alarmPointsItem.length > 0) {
          alarmPoints.push(JSON.parse(JSON.stringify(alarmPointsItem)));
          that.globalAlarmPoints.push(JSON.parse(JSON.stringify(alarmPointsItem)));
          alarmPointsItem = [];
        }
        if (warningPointsItem.length > 0) {
          warningPoints.push(JSON.parse(JSON.stringify(warningPointsItem)));
          that.globalWarningPoints.push(JSON.parse(JSON.stringify(warningPointsItem)));
          warningPointsItem = [];
        }
        if (manifestation != 'progress') {
          // this.markerPath = []
        }
        if (manifestation != 'progress') {
          //创建线对象
          runPoints.forEach(item => {
            let runLine = new T.Polyline(item, { color: '#2283EB', weight: 3, opacity: 0.8, type: 'run' });
            this.markerPath.push(runLine)
            this.map.addOverLay(runLine);
          })
          alarmPoints.forEach(item => {
            let alarm = new T.Polyline(item, { color: '#c62828', weight: 3, opacity: 0.8, type: 'alarm' });
            this.markerPath.push(alarm)
            this.map.addOverLay(alarm);
          })
          warningPoints.forEach(item => {
            let warning = new T.Polyline(item, { color: '#f5a623', weight: 3, opacity: 0.8, type: 'work' });
            this.markerPath.push(workLine)
            this.map.addOverLay(warning);
          })
        }

      }
      // }
    },
    // 车辆纠偏
    onCorrectTrack (data) {
      this.correctTrack = data
      this.getTrack()
    },
    // 纠偏数据
    getNavigationData (data, num) { },
    // 车辆/人员图标
    addMarker (icon, lng, lat) {
      let icon2 = new T.Icon({
        iconUrl: icon,
        iconSize: new T.Point(33, 37),
        iconAnchor: new T.Point(10, 25)
      });
      let marker = new T.Marker(new T.LngLat(lng, lat), { icon: icon2 });
      this.map.addOverLay(marker);
    },
    // 在地图上绘制标记点
    drawMarker (obj) {
      let icon = ''
      let size = ''
      let anchor = ''
      let arr = []
      if (this.oldType == 'car') {
        switch (obj.messageType) {
          case 1:
            icon = require('../../assets/main/ico_alarm.png')
            size = new T.Point(15, 15)
            anchor = new T.Point(7, 6)
            break;
          case 2:
            icon = require('../../assets/main/ico_abnormal.png')
            size = new T.Point(15, 15)
            anchor = new T.Point(7, 6)
            break;
          case 3:
            icon = require('../../assets/main/ico_stop.png')
            size = new T.Point(24, 27)
            anchor = new T.Point(10, 25)
            break;
          case '4':
            icon = obj.isClean == 1 ? require('../../assets/main/bin_done.png') : require('../../assets/main/bin_pending.png')
            size = new T.Point(22, 25)
            anchor = new T.Point(10, 25)
            break;
          default:
            break;
        }
      } else {
        switch (obj.messageType) {
          case 1:
            icon = require('../../assets/main/ico_alarm.png')
            size = new T.Point(15, 15)
            anchor = new T.Point(7, 6)
            break;
          case 2:
            icon = require('../../assets/main/ico_abnormal.png')
            size = new T.Point(15, 15)
            anchor = new T.Point(7, 6)
            break;
          case 3:
            icon = require('../../assets/main/ico_abnormal.png')
            size = new T.Point(15, 15)
            anchor = new T.Point(7, 6)
            break;
          case '4':
            icon = obj.isClean == 1 ? require('../../assets/main/bin_done.png') : require('../../assets/main/bin_pending.png')
            size = new T.Point(22, 25)
            anchor = new T.Point(10, 25)
            break;
          default:
            break;
        }
      }
      //创建图片对象
      let iconObj = new T.Icon({
        iconUrl: icon,
        iconSize: size,
        iconAnchor: anchor
      });
      let lng = obj.lng
      let lat = obj.lat
      var point = new T.LngLat(lng, lat);
      var marker = new T.Marker(point, { icon: iconObj });// 创建标注
      marker.id = obj.messageTypeId
      marker.type = obj.messageType
      this.marker.push(marker)
      this.map.addOverLay(marker);
      if (obj.messageType != '4') {
        this.markerMessage.push(marker)
        marker.addEventListener('click', (e) => {
          this.openPoptip(obj, e.lnglat)
        });
      } else {
        marker.addEventListener('click', (e) => {
          this.onClickBin(obj)
        });
      }
    },
    // 打开气泡
    openPoptip (obj, lnglat) {
      let content = this.getContent(obj)
      let markerInfoWin = new T.InfoWindow(content, { offset: new T.Point(1, -5), autoPan: true, closeButton: false, closeOnClick: true });
      this.map.openInfoWindow(markerInfoWin, lnglat)
    },
    // 获取气泡content
    getContent (obj) {
      let content = '';
      let classs = ''
      if (this.oldType == 'car') {
        switch (obj.messageType) {
          case 1:
            status = '报警';
            classs = 'p-one'
            break;
          case 2:
            status = '违规';
            classs = 'p-tow'
            break;
          case 3:
            status = '停留';
            classs = 'p-three'
            break;
          default:
            break;
        }
      } else {
        switch (obj.messageType) {
          case 1:
            status = '报警';
            classs = 'p-one'
            break;
          case 2:
            status = '违规';
            classs = 'p-tow'
            break;
          case 3:
            status = '违规';
            classs = 'p-tow'
            break;
          default:
            break;
        }
      }

      content = `
              <div class="poptip">
                <div class="poptip-title">
                  <p class="${classs}">
                    ${status}
                  </p>
                  <span>${this.nameText}</span>
                </div>
                <div class="poptip-start">
                  <span>开始时间:</span>
                  <span>${obj.time}</span>
                </div>
                <div>
                  <span>持续时长:</span>
                  <span>${obj.content}</span>
                </div>
                <div class="poptip-cont">
                  <p>
                  <i class="iconfont icon-weizhishi"></i>
                    ${obj.address}
                  </p>
                </div>
              </div>
            `
      return content;
    },
    // 点击轨迹节点详情
    onClickTrackNodeDetailItem (item) {
      // let boo = this.markerMessage.some(items => items.id == item.id)
      // console.log(boo);
      this._CarTrack.pause();
      this.pause = false;
      if (this.markerMessage.length > 0) {
        this.markerMessage.forEach(item => {
          item.hide()
        })
      }
      if (item.messageType != 3 && item.dangerList != null && item.dangerList.length > 1) {
        this.alarmAndViolationTrack(item.dangerList)
      }
      this.isStatusShow = !this.isStatusShow
      if (this.isStatusShow) {
        this.drawMarker(item)
        this.messageTypeId = item.id
        if (item.lng && item.lat) {
          this.panToMap(item)
          this.openPoptip(item, new T.LngLat(item.lng, item.lat))
        } else {
          this.$Message.info('暂无该定位信息')
        }
      } else {
        this.markerMessage.forEach(item => {
          item.hide()
        })
        this.map.closeInfoWindow()
        if (item.id != this.messageTypeId) {
          this.drawMarker(item)
          this.messageTypeId = item.id
          if (item.lng && item.lat) {
            this.panToMap(item)
            this.openPoptip(item, new T.LngLat(item.lng, item.lat))
          } else {
            this.$Message.info('暂无该定位信息')
          }
        }
      }

    },
    // 点击收集点
    onClickBin (item) {
      let content = '';
      let classs = ''
      let status = ''
      if (item.isClean == 1) {
        status = '已清'
        classs = 'p-four'
      } else {
        status = '未清'
        classs = 'p-tow'
      }
      content = `
              <div class="bin-box">
                <div class="bin-box-title">
                  <p>
                    ${item.faclname}
                  </p>
                  <span class="${classs}">${status}</span>
                </div>
                <div class="bin-box-cont">
                  <p>
                  <i class="iconfont icon-weizhishi"></i>
                    ${item.facladdrdetail}
                  </p>
                </div>
              </div>
            `
      let markerInfoWin = new T.InfoWindow(content, { offset: new T.Point(3, -20), autoPan: true, closeButton: false, closeOnClick: true });
      this.map.openInfoWindow(markerInfoWin, new T.LngLat(item.lng, item.lat))
      this.panToMap(item)
    },
    // 报警、违规轨迹
    alarmAndViolationTrack (data) {
      let points = []
      let colors = ''
      switch (data[0].messageType) {
        case 1:
          colors = '#c62828'
          break;
        case 2:
          colors = '#f5a623'
          break;
        default:
          break;
      }
      data.forEach(item => {
        points.push(new T.LngLat(item.lng, item.lat))
      })
      let line = new T.Polyline(points, { color: colors, weight: 3, opacity: 0.8 })
      this.markerMessage.push(line)
      this.map.addOverLay(line)
    },
    // 点击图标显隐多选
    onDot (data) {
      this.marker.forEach(item => {
        if (data.length > 0) {
          item.show()
        } else {
          item.hide()
        }
      });
    },
    // 点击路径的显隐
    onPath (data) {
      if (this.isDrivingTrackShow) {
        let longArr = []
        let shortArr = []
        let boo = this.pathCheckbox.length < data.length
        if (boo) {
          longArr = data
          shortArr = this.pathCheckbox
        } else {
          longArr = this.pathCheckbox
          shortArr = data
        }
        let str = longArr.filter(item => !shortArr.some(items => item == items))
        let pathArr = this.markerPath.filter(item => item.options.type == str)
        if (boo) {
          pathArr.forEach(items => {
            if (items.options.type == 'alarm') {
              items.setColor('#c62828')
            } else if (items.options.type == 'getout') {
              items.setColor('#f5a623')
            } else {
              items.setColor('#7ed321')
            }
          });
        } else {
          pathArr.forEach(items => {
            items.setColor('#2283EB')
          });
        }
        this.pathCheckbox = data
      } else {
        let newArr = data.filter(item => item != 'run')
        this.markerPath.forEach(item => {
          if (item.options.type) {
            if (newArr.indexOf(item.options.type) == -1) {
              item.hide()
            }
            if (newArr.indexOf(item.options.type) != -1) {
              item.show()
            }
          }
        });
      }
    },
    // 纠偏轨迹显隐
    onCorrectTrackIsShow (data) {
      this.markerPath.forEach(item => {
        if (data) {
          item.show()
        } else {
          item.hide()
        }
      });
    },
    // 显隐纠偏之后垃圾桶
    onBinDotIsShow (data) {
      this.marker.forEach(item => {
        if (data) {
          item.show()
        } else {
          item.hide()
        }
      });
    },
    // 点击行驶轨迹显隐
    onIsDrivingTrack (data) {
      let newArr = this.oldPathCheckbox.filter(item => !this.pathCheckbox.some(items => items == item))
      newArr.push('run')
      this.isDrivingTrackShow = data
      this.markerPath.forEach(item => {
        newArr.forEach(items => {
          if (item.options.type == items) {
            if (data) {
              item.show()
            } else {
              item.hide()
            }
          }
        })

      });
    },
    // 点击动画显隐
    onIsAnimation (data) {
      this.isAnimation = data
      this.getInquireTrack()
    },
    // 绘制网格
    drawGrid (item) {
      // if (this.polygon) {
      //   this.map.removeOverLay(this.polygon)
      // }
      let config = {
        color: '#5151a7',
        weight: 3,
        opacity: 0.8,
        fillColor: '#5151a7',
        fillOpacity: 0.2
      }
      let points = []
      this.polygon
      let lngLats = JSON.parse(item.lnglat)
      if (item.areaType == 3) {
        this.polygon = new T.Circle(new T.LngLat(item.lng, item.lat), item.radius, config)
      } else if (item.areaType == 4) {
        var bounds = new T.LngLatBounds(new T.LngLat(lngLats.Lq.lng, lngLats.Lq.lat), new T.LngLat(lngLats.kq.lng, lngLats.kq.lat));
        this.polygon = new T.Rectangle(bounds, config);
      } else {
        lngLats.forEach(items => {
          points.push(new T.LngLat(items.lng, items.lat));
        });
        this.polygon = item.areaType == 5 ? new T.Polygon(points, config) : new T.Polyline(points, config)
      }
      this.binArr = item.facilitiesVoList
      this.markerGrid.push(this.polygon)
      //向地图上添加网格
      this.map.addOverLay(this.polygon);
    },
    // 垃圾箱数据
    getDinData () {
      this.slider = 0
      let binArr = this.binArr
      let binArrDone = this.binArrDone
      if (this.oldType == 'car') {
        binArr.forEach(item => {
          item.messageType = '4'
          if (!this.isAnimation) {
            binArrDone.forEach(items => {
              if (items.id == item.id) {
                item.isClean = 1
              }
            })
          } else {
            item.isClean = 0
          }
          this.drawMarker(item)
        });
      }
      if (this.pause) {
        this._CarTrack.stop();
        this.slider = 0
        this.pause = false;
      }
    },
    // 作业网格显隐
    onWorkGridSwitch (on) {
      this.markerGrid.forEach(item => {
        if (on) {
          item.show()
        } else {
          item.hide()
        }
      });
    },
    // 设置地图中心点
    panToMap (obj) {
      this.map.panTo(new T.LngLat(obj.lng, obj.lat))
    },
    setDateInterval (pos) {
      this.currentDay = pos;
      let today = this.getLastOrNextFewDateBy(0);
      switch (pos) {
        case 0:
          this.$emit('');
          this.dateInterval = [today + ' 00:00:00', today + ' 23:59:59'];
          break;
        case 1:
          let yestoday = this.getLastOrNextFewDateBy(-1);
          this.dateInterval = [yestoday + ' 00:00:00', yestoday + ' 23:59:59'];
          break;
        case 2:
          let threeday = this.getLastOrNextFewDateBy(-2);
          this.dateInterval = [threeday + ' 00:00:00', today + ' 23:59:59'];
          break;
      }
    },
    setDateInterval2 (d) {
      this.dateInterval = d;
    },
    getLastOrNextFewDateBy (day) {
      let today = new Date();
      let lastOrNextDate = today.getTime() + 1000 * 60 * 60 * 24 * day;
      today.setTime(lastOrNextDate);
      let year = today.getFullYear();
      let month = today.getMonth() + 1;
      let date = today.getDate();
      month = month < 10 ? '0' + month : month;
      date = date < 10 ? '0' + date : date;
      return year + '-' + month + '-' + date;
    },
    // 页数改变
    onPageChange (event) {
      this.trackPageNum = event
      if (this.oldType == 'car') {
        this.getCarTrackNodeListPage()
      } else if (this.oldType == 'person') {
        this.getPersonTrackNodeListPage()
      }
    },
    // 每页条数改变
    onPageSizeChange (event) {
      this.trackPageSize = event
      if (this.oldType == 'car') {
        this.getCarTrackNodeListPage()
      } else if (this.oldType == 'person') {
        this.getPersonTrackNodeListPage()
      }
    },
    onClickIndexName () {
      window.open('/main', '_blank')
    },
    // 数组切割 
    cutting (array, size) {
      //获取数组的长度，如果你传入的不是数组，那么获取到的就是undefined
      const length = array.length
      //判断不是数组，或者size没有设置，size小于1，就返回空数组
      if (!length || !size || size < 1) {
        return []
      }
      //核心部分
      let index = 0 //用来表示切割元素的范围start
      let resIndex = 0 //用来递增表示输出数组的下标

      //根据length和size算出输出数组的长度，并且创建它。
      let result = new Array(Math.ceil(length / size))
      //进行循环
      while (index < length) {
        //循环过程中设置result[0]和result[1]的值。该值根据array.slice切割得到。
        result[resIndex++] = array.slice(index, (index += size))
      }
      //输出新数组
      return result
    },
    //点击地图
    onClickMap () {
      this.markerMessage.forEach(item => {
        item.hide()
      })
      this.isStatusShow = false
      this.map.closeInfoWindow()
    },
  },
}
</script>

<style lang='scss' scoped>
.main {
  width: 100%;
  height: 100%;
  .mapDiv {
    width: 100%;
    height: 100%;
    z-index: 1;
  }
  .meng {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    z-index: 300;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    .item {
      height: 60px;
      display: flex;
      align-items: center;
      border-radius: 10px;
      padding: 0 20px;
      color: #fff;
      position: fixed;
      left: 50%;
      bottom: 70px;
      transform: translate(-50%);
      font-size: 18px;
      background: rgba(0, 0, 0, 0.8);
      .meng-icon {
        font-size: 24px;
        animation: img-rotate 2.5s linear infinite;
      }
      .line {
        width: 3px;
        height: 25px;
        background: #fff;
        margin: 0 10px;
      }
      @keyframes img-rotate {
        from {
          transform: rotate(360deg);
        }
        to {
          transform: rotate(0deg);
        }
      }
    }
  }
}

.top-title {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-image: url(../../assets/main/bg_title.png);
  background-position: center 0;
  height: 80px;
  font-size: 26px;
  text-align: center;
  color: rgba(255, 255, 255, 0.85);
  z-index: 200;
  line-height: 2.2;
  letter-spacing: 3.5px;
  span {
    cursor: pointer;
  }
}

.hint-box {
  z-index: 200;
  position: absolute;
  left: 8px;
  bottom: 10px;
  width: 256px;
  display: flex;
  flex-wrap: wrap;
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 30px 0 20px 0;
  box-sizing: border-box;
  background: rgba(25, 34, 41, 0.85);

  .hint-box-icon {
    position: absolute;
    top: 7px;
    right: 12px;
    display: inline-block;
    border: 1px solid rgba(255, 255, 255, 0.1);
    font-size: 12px;
    color: #c9c9c9;
    font-style: normal;
    padding: 1px 4px;
    cursor: pointer;
  }

  div {
    width: 50%;
    display: -webkit-box;
    align-items: center;
    padding: 5px 0;

    p {
      display: block;
      width: 85px;
      text-align: right;
      padding-right: 6px;
      font-size: 12px;
      color: rgba(255, 255, 255, 0.65);
    }

    span {
      display: flex;
      align-items: center;
    }
  }

  .rect {
    span {
      font-size: 12px;
      color: rgba(255, 255, 255, 0.45);
    }
  }

  .line {
    span {
      samp {
        width: 16px;
        height: 2px;
        background: #28a063;
      }
    }
  }

  .line:nth-child(5) {
    span {
      samp {
        background: rgb(31, 132, 235);
      }
    }
  }

  .combine {
    span {
      samp {
        width: 14px;
        height: 2px;
        background: rgb(250, 173, 20);
      }

      em {
        display: inline-block;
        width: 8px;
        height: 8px;
        border-width: 2px;
        border-style: solid;
        border-radius: 50%;
        box-sizing: border-box;
        margin-left: 3px;
        border-color: rgb(250, 173, 20);
        background-color: rgba(255, 235, 195, 0.85);
      }
    }
  }

  .combine:nth-child(7) {
    span {
      samp {
        background: rgb(245, 34, 45);
      }

      em {
        border-color: rgb(245, 34, 45);
        background-color: rgba(255, 235, 195, 0.85);
      }
    }
  }
}

.hint-box::before {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 1px;
  content: "";
  border-left: 3px solid #fff;
  border-right: 3px solid #fff;
}

.progress {
  width: 55%;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%);
  box-shadow: 0 0 7px 0 rgba(51, 51, 51, 0.18);
  border-radius: 6px 6px 0 0;
  background: rgba(25, 34, 41, 0.85);
  z-index: 100;

  .progress-box {
    display: flex;
    padding: 0 12px;
    border-radius: 6px 6px 0 0;
    background: rgba(67, 77, 85, 0.9);
    align-items: center;
    justify-content: space-between;
    height: 42px;

    .progress-box-left {
      display: flex;
      width: 100px;
      border-right: 1px solid rgba(255, 255, 255, 0.1);
      align-items: center;
      color: rgba(255, 255, 255, 0.7);

      p {
        display: block;
        width: 50px;
      }

      ::v-deep {
        .ivu-select-selection {
          background-color: transparent;
          color: rgba(255, 255, 255, 0.7);
          border: none;
        }
      }
    }

    .progress-box-icon {
      display: flex;

      .progress-box-icon-icon {
        display: block;
        width: 24px;
        height: 24px;
        border: 2px solid rgba(255, 255, 255, 0.85);
        border-radius: 50%;
        text-align: center;
        line-height: 20px;
        color: rgba(255, 255, 255, 0.85);
        font-size: 12px;
        margin-left: 12px;
        cursor: pointer;
      }
    }

    .progress-box-slider {
      min-width: 300px;
      flex-grow: 1;
      margin-left: 16px;
      position: relative;

      ::v-deep {
        .ivu-slider-wrap {
          height: 8px;
          background-color: #1f84eb;

          .ivu-slider-bar {
            height: 8px;
            background: #1f84eb;
          }

          .ivu-slider-button-wrap {
            .ivu-tooltip {
              .ivu-tooltip-rel {
                .ivu-slider-button {
                  width: 16px;
                  height: 16px;
                }
              }
            }
          }
        }
      }
    }

    .progress-box-detail {
      display: block;
      color: rgba(255, 255, 255, 0.65);
      border-left: 1px solid rgba(255, 255, 255, 0.1);
      padding-left: 16px;
      margin-left: 16px;
      cursor: pointer;

      .progress-box-detail-icon {
        transform: rotate(180deg);
        transition: all 0.5s;
      }

      .progress-box-detail-icon-in {
        transform: rotate(0deg);
      }
    }
  }

  .detail-box {
    ::v-deep {
      .ivu-tabs-bar {
        border-bottom: 1px solid rgba(240, 240, 240, 0.1);
      }

      .ivu-tabs-tab {
        color: #fff;
      }

      .ivu-tabs-tab-active {
        color: #2dbd9a;
      }
    }

    .detail-box-track {
      // height: 330px;
      // padding:10px 0;
      padding-bottom: 0;
      background-color: transparent;

      ::v-deep {
        .ivu-table {
          background-color: #191b1e;
        }
        .ivu-table .ivu-table-header th {
          color: rgba(255, 255, 255, 0.65);
          background-color: #1d2023;
        }

        .ivu-table td {
          color: rgba(255, 255, 255, 0.65);
          background-color: #191b1e;
        }

        .ivu-table:before {
          background-color: #191b1e;
        }
      }
    }
  }
}

::v-deep {
  .tdt-infowindow {
    .tdt-infowindow-tip-container {
      .tdt-infowindow-tip {
        background: rgba(0, 0, 0, 0.85);
      }
    }
    .tdt-infowindow-content-wrapper {
      background: rgba(0, 0, 0, 0.75);
      color: #83898e;
      border-radius: 6px;
      cursor: pointer;
      .tdt-infowindow-content {
        width: 200px;
        .poptip {
          color: #ccc;
          .poptip-treename {
            margin-bottom: 10px;
          }
          .poptip-title {
            display: flex;
            justify-content: space-between;
            padding-bottom: 10px;
            margin-bottom: 0px;
            color: #ccc;
            p {
              border-style: solid;
              border-width: 1px;
              padding: 2px 7px 0;
              margin-right: 7px;
              border-color: #c6c6c6;
              background: rgba(201, 201, 201, 0.3);
            }
            .p-one {
              border-color: #e3170d;
              background: rgba(176, 23, 31, 0.3);
            }
            .p-tow {
              border-color: #e19f1b;
              background: rgba(255, 153, 18, 0.3);
            }
            .p-three {
              border-color: #c6c6c6;
              background: rgba(201, 201, 201, 0.3);
            }
            .p-four {
              border-color: #0ac194;
              background: rgba(40, 240, 98, 0.3);
            }
            span {
              margin-left: auto;
              padding: 2px 6px;
              border-radius: 10px;
              font-size: 12px;
            }
            .on {
              color: #32b16c;
              background: rgba(50, 177, 108, 0.2);
            }
            .off {
              color: #84919e;
              background: rgba(132, 145, 158, 0.2);
            }
            .task {
              color: #1890ff;
              background: rgba(24, 144, 255, 0.2);
            }
            .alert {
              color: #c30b19;
              background: rgba(195, 11, 25, 0.2);
            }
          }
          .poptip-start {
            margin-top: 3px;
            margin-bottom: 8px;
          }
          .poptip-cont {
            color: #83898e;
            border-top: 1px solid rgba(255, 255, 255, 0.2);
            padding: 10px 0;
            margin-top: 10px;
          }
          .poptip-speed {
            margin-bottom: 10px;
            color: rgba(255, 255, 255, 0.6);
            font-size: 12px;
            color: #ccc;
            display: flex;
            // justify-content: space-between;
            p {
              flex: 1;
            }
          }
          .btn-box {
            display: flex;
            justify-content: space-between;
            .item {
              flex: 1;
              background: rgba(255, 255, 255, 0.1);
              color: #eeeeee;
              border-radius: 4px;
              text-align: center;
              line-height: 32px;
              height: 32px;
              margin-right: 8px;
              cursor: pointer;
              a {
                color: #fff;
              }
            }
            .item:last-child {
              margin-right: 0;
            }
          }
          .area-box {
            display: flex;
            margin-bottom: 6px;
            align-items: center;
            justify-content: center;
            div {
              display: flex;
              flex-direction: column;
              align-items: center;
              width: 49%;
              p {
                margin-bottom: 8px;
              }
              span {
                color: #eee;
                font-size: 16px;
              }
            }
            div:nth-of-type(1) {
              border-right: 1px solid rgba(255, 255, 255, 0.2);
            }
          }
        }
        p {
          margin: 0;
        }
      }
    }
  }
  .bin-box {
    color: #fff;
    font-size: 14px;
    .bin-box-treename {
      margin-bottom: 10px;
    }
    .bin-box-title {
      display: flex;
      justify-content: space-between;
      padding-bottom: 10px;
      margin-bottom: 0px;
      color: #fff;
      p {
        padding: 2px 7px 0;
        margin-right: 7px;
      }
      .p-tow {
        color: #f3aa58;
      }
      .p-four {
        color: #a0d878;
      }
      span {
        margin-left: auto;
        padding: 2px 6px;
        border-radius: 10px;
      }
    }
    .bin-box-cont {
      color: #fff;
      padding: 10px 0;
      margin-top: 10px;
    }
  }
}
</style>