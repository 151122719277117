<template>
  <div>
    <div class="right"
         :class='classIsOpen'>
      <div class="right-title">
        <div class="flex a-center j-between">
          <div class="m-r-10">轨迹节点</div>
          <Select v-model="trackTableSelect"
                  style="width:160px;"
                  @on-change="onChangeTrackTableData">
            <Option v-for="(item,index) in dateList"
                    :value="index"
                    :key="index">{{ item.date }}</Option>
          </Select>
        </div>
        <div @click="onIsOpen">
          <Icon type="ios-close-circle-outline"
                v-if="isOpen"
                style="font-size:30px" />
          <Icon type="ios-add-circle-outline"
                v-else
                style="font-size:30px" />
        </div>
      </div>
      <Tabs :animated="false"
            v-show="isOpen">
        <TabPane label="全部">
          <div class="right-timeline">
            <Timeline v-if="oldType == 'car'">
              <TimelineItem v-for="(item,index) in allTimeList"
                            :key="index">
                <div @click="onClickTrackNodeDetailItem(item)"
                     class="timeline-item">
                  <div class="all-status">
                    <p class="p-one"
                       v-if="item.messageType == 1">报警</p>
                    <p class="p-tow"
                       v-else-if="item.messageType == 2">违规</p>
                    <p class="p-three"
                       v-else-if="item.messageType == 3">停留</p>
                    <p class="p-four"
                       v-else-if="item.messageType == 4">移动</p>
                    <span>{{item.time}}</span>
                  </div>
                  <div class="all-text-one"
                       v-if="item.messageType == 1">
                    {{item.content}}
                  </div>
                  <div class="all-text-tow"
                       v-else-if="item.messageType == 2">
                    {{item.content}}
                  </div>
                  <div class="all-text"
                       v-else>
                    {{item.content}}
                  </div>
                  <span class="poptip-cont">
                    <span>
                      <i class="iconfont icon-weizhishi"></i>
                    </span>
                    <span>{{item.address}}</span>
                  </span>
                </div>
              </TimelineItem>
            </Timeline>
            <Timeline v-else>
              <TimelineItem v-for="(item,index) in allTimeList"
                            :key="index">
                <div @click="onClickTrackNodeDetailItem(item)"
                     class="timeline-item">
                  <div class="all-status">
                    <p class="p-one"
                       v-if="item.messageType == 1">报警</p>
                    <p class="p-tow"
                       v-else>违规</p>
                    <span>{{item.time}}</span>
                  </div>
                  <div class="all-text-one"
                       v-if="item.messageType == 1">
                    {{item.content}}
                  </div>
                  <div class="all-text-tow"
                       v-else>
                    {{item.content}}
                  </div>
                  <span class="poptip-cont">
                    <span>
                      <i class="iconfont icon-weizhishi"></i>
                    </span>
                    <span>{{item.address}}</span>
                  </span>
                </div>
              </TimelineItem>
            </Timeline>
            <div class="noData"
                 v-if="allTimeList.length == 0">
              <img src="../../../../assets/main/blank_black.png">
              <p>无数据</p>
            </div>
          </div>
        </TabPane>
        <TabPane label="报警点">
          <div class="right-timeline">
            <Timeline>
              <TimelineItem v-for="(item,index) in alarmList"
                            :key="index">
                <div @click="onClickTrackNodeDetailItem(item)"
                     class="timeline-item">
                  <div class="all-status">
                    <p class="p-one">{{item.typeName}}</p>
                    <span>{{item.time}}</span>
                  </div>
                  <div class="all-text-one">
                    {{item.content}}
                  </div>
                  <span class="poptip-cont">
                    <span>
                      <i class="iconfont icon-weizhishi"></i>
                    </span>
                    <span>{{item.address}}</span>
                  </span>
                </div>
              </TimelineItem>
            </Timeline>
            <div class="noData"
                 v-if="alarmList.length == 0">
              <img src="../../../../assets/main/blank_black.png">
              <p>无数据</p>
            </div>
          </div>
        </TabPane>
        <TabPane label="违规点">
          <div class="right-timeline">
            <Timeline>
              <TimelineItem v-for="(item,index) in violationList"
                            :key="index">
                <div @click="onClickTrackNodeDetailItem(item)"
                     class="timeline-item">
                  <div class="all-status">
                    <p class="p-tow">违规</p>
                    <span>{{item.time}}</span>
                  </div>
                  <div class="all-text-tow">
                    {{item.content}}
                  </div>
                  <span class="poptip-cont">
                    <span>
                      <i class="iconfont icon-weizhishi"></i>
                    </span>
                    <span>{{item.address}}</span>
                  </span>
                </div>
              </TimelineItem>
            </Timeline>
            <div class="noData"
                 v-if="violationList.length == 0">
              <img src="../../../../assets/main/blank_black.png">
              <p>无数据</p>
            </div>
          </div>
        </TabPane>
        <TabPane label="停留点"
                 v-if="oldType == 'car'">
          <div class="right-timeline">
            <Timeline>
              <TimelineItem v-for="(item,index) in stayList"
                            :key="index">
                <div @click="onClickTrackNodeDetailItem(item)"
                     class="timeline-item">
                  <div class="all-status">
                    <p class="p-three">停留</p>
                    <span>{{item.time}}</span>
                  </div>
                  <div class="all-text">
                    {{item.content}}
                  </div>
                  <span class="poptip-cont">
                    <span>
                      <i class="iconfont icon-weizhishi"></i>
                    </span>
                    <span>{{item.address}}</span>
                  </span>
                </div>
              </TimelineItem>
            </Timeline>
            <div class="noData"
                 v-if="stayList.length == 0">
              <img src="../../../../assets/main/blank_black.png">
              <p>无数据</p>
            </div>
          </div>
        </TabPane>
      </Tabs>
    </div>
    <ul class="control-box">
      <li @click.stop="onClickEnlargement()">
        <Icon custom="i-icon icon-tianjia1"
              size="22"
              color="#B1B2B3" />
      </li>
      <li @click.stop="onClickZoomOut()">
        <Icon custom="i-icon icon-zuixiaohua"
              size="22"
              color="#B1B2B3" />
      </li>
      <li>
        <!-- 右下角作业网格，违规点 -->
        <Poptip placement="left-end">
          <Icon custom="i-icon icon-huizong"
                size="22"
                color="#B1B2B3" />
          <div slot="content">
            <div class="layer-list">
              <div class="layer-list-switch layer-list-item">
                <div class="layer-list-switch-div"
                     @click.stop="onWorkGrid">
                  <p>作业网格</p>
                  <i-switch v-model="workGridSwitch" />
                </div>
                <!-- <div class="layer-list-switch-div">
                  <p>电子围栏</p>
                  <i-switch v-model="railGridSwitch" />
                </div> -->
              </div>
              <div class="layer-list-radio layer-list-item">
                <div class="layer-list-radio-title">轨迹</div>
                <div class="layer-list-radio-cont">
                  <RadioGroup v-model="trackRadio"
                              @on-change="onCorrectTrack">
                    <Radio label="original">
                      原始
                    </Radio>
                    <!-- <Radio label="correct">
                      纠偏
                    </Radio> -->
                  </RadioGroup>
                </div>
              </div>
              <div v-if="isCorrectTrack">
                <div class="layer-list-pathcheckbox  layer-list-item">
                  <CheckboxGroup v-model="pathCheckbox"
                                 @on-change="onPath">
                    <Checkbox label="work">
                      作业轨迹
                    </Checkbox>
                  </CheckboxGroup>
                </div>
                <div class="layer-list-pathcheckbox  layer-list-item">
                  <Checkbox v-model="drivingTrack"
                            style="margin-bottom:10px"
                            @on-change="onIsDrivingTrack">行进轨迹</Checkbox>
                </div>
                <!-- <div class="layer-list-dotcheckbox  layer-list-item">
                  <CheckboxGroup v-model="dotCheckbox"
                                 @on-change="onDot">
                    <Checkbox label="bin"
                              v-if="oldType != 'person'">
                      收集点
                    </Checkbox>
                  </CheckboxGroup>
                </div> -->
                <div class="layer-list-pattern  layer-list-item"
                     v-if="oldType != 'person'">
                  <div class="layer-list-pattern-title">
                    模式
                  </div>
                  <Checkbox v-model="animation"
                            style="margin-bottom:10px"
                            @on-change="onIsAnimation"
                            :disabled="isDisabledAnimation">展示动画</Checkbox>
                </div>
              </div>
              <div class="layer-list-pattern  layer-list-item"
                   style="display:flex"
                   v-else>
                <div>
                  <Checkbox v-model="personCorrectTrack"
                            style="margin-bottom:10px"
                            @on-change="onCorrectTrackIsShow">纠偏轨迹</Checkbox>
                </div>
                <Checkbox v-model="personDot"
                          style="margin-bottom:10px"
                          @on-change="onBinDotIsShow"
                          v-if="oldType != 'person'">收集点</Checkbox>
              </div>
            </div>
          </div>
        </Poptip>
      </li>
    </ul>
  </div>

</template>

<script>
import bus from '@/utils/bus'
export default {
  props: {
    oldType: String,
    carId: String,
    dateInterval: Array,
    mapArr: '',
    dateList: Array
  },
  data () {
    return {
      allTimeList: [],
      alarmList: [],
      violationList: [],
      stayList: [],
      workGridSwitch: true,
      railGridSwitch: true,
      trackRadio: 'original',
      pathCheckbox: ['work', 'alarm', 'getout', 'run'],
      // dotCheckbox: ['1', '2', '3', '4'],
      dotCheckbox: ['bin'],
      drivingTrack: true,
      animation: false,
      map: '',
      allText: '',
      isOpen: true,
      classIsOpen: 'open',
      personCorrectTrack: true,
      personDot: true,
      isCorrectTrack: true,
      isDisabledAnimation: false,
      trackTableSelect: 0,
    }
  },
  watch: {
    dateInterval (v1, v2) {
      this.getCarMoveInfo();
    },
    dateList () {
      this.trackTableSelect = 0
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      bus.$on('onSwitch', data => {
        if (data) {
          this.pathCheckbox = ['work', 'alarm', 'getout', 'run']
          this.dotCheckbox = ['1', '2', '3', '4']
        }
      })
      bus.$on('resetTrackCarMessageData', () => {
        this.allTimeList = []
        this.alarmList = []
        this.stayList = []
        this.violationList = []
      })
    },
    // 获取轨迹节点数据
    getCarMoveInfo () {
      switch (this.oldType) {
        case 'car':
          this.getCarTrackNodeList()
          break;
        case 'person':
          this.getPersonTrackNodeList()
          break;
        default:
          break;
      }
    },
    // 获取车辆轨迹节点
    getCarTrackNodeList () {
      // 降序 日期新的在前边
      bus.$off('trackCarMessageData')
      bus.$on('trackCarMessageData', arr => {
        let data = arr
        this.allTimeList = data.orderByAlarmAndViolationCars
        // this.allTimeList = this.allTimeList.sort(function (a, b) {
        //   return new Date(b.time).getTime() - new Date(a.time).getTime()
        // })
        this.alarmList = data.alarmCarList
        // this.alarmList = this.alarmList.sort(function (a, b) {
        //   return new Date(b.time).getTime() - new Date(a.time).getTime()
        // })
        this.stayList = data.warningStayCollect
        // this.stayList = this.stayList.sort(function (a, b) {
        //   return new Date(b.time).getTime() - new Date(a.time).getTime()
        // })
        this.violationList = data.otherWarningCollect
        // this.violationList = this.violationList.sort(function (a, b) {
        //   return new Date(b.time).getTime() - new Date(a.time).getTime()
        // })
      })
    },
    // 获取人员轨迹节点
    getPersonTrackNodeList () {
      bus.$on('trackPersonMessageData', arr => {
        let data = arr
        this.allTimeList = data.orderByAlarmAndViolationEmployees
        this.alarmList = data.alarmEmployeeList
        this.violationList = data.employeeViolationList
      })
    },
    // 放大
    onClickEnlargement () {
      this.mapArr.zoomIn();
    },
    // 缩小
    onClickZoomOut () {
      this.mapArr.zoomOut()
    },
    // 点击传递item
    onClickTrackNodeDetailItem (item) {
      this.$emit('onClickTrackNodeDetailItem', item);
    },
    // 点击传递路线多选
    onPath (data) {
      this.$emit('onPath', data)
    },
    // 点击传递图标坐标点多选
    onDot (data) {
      let arr = []
      this.$emit('onDot', data)
    },
    // 点击传递行驶轨迹显隐
    onIsDrivingTrack (data) {
      this.$emit('onIsDrivingTrack', data)
    },
    // 点击传递动画显隐
    onIsAnimation (data) {
      // this.isDisabledAnimation = true
      this.$emit('onIsAnimation', data)
      // setTimeout(() => {
      // this.isDisabledAnimation = false
      // }, 5000);
    },
    // 点击轨迹纠偏
    onCorrectTrack (data) {
      this.trackRadio = 'original'
      this.pathCheckbox = ['work', 'alarm', 'getout', 'run']
      this.dotCheckbox = ['bin']
      this.drivingTrack = true
      this.animation = false
      this.personDot = true
      this.personCorrectTrack = true
      this.workGridSwitch = true
      this.isCorrectTrack = !this.isCorrectTrack
      this.$emit('onCorrectTrack', data)
    },
    // 点击显隐纠偏完的轨迹
    onCorrectTrackIsShow (data) {
      this.$emit('onCorrectTrackIsShow', data)
    },
    // 点击显隐纠偏完的垃圾桶
    onBinDotIsShow (data) {
      this.$emit('onBinDotIsShow', data)
    },
    // 作业网格开关
    onWorkGrid () {
      this.$emit('onWorkGridSwitch', this.workGridSwitch)
    },
    // 侧边关闭开启
    onIsOpen () {
      this.isOpen = !this.isOpen
      this.classIsOpen = this.isOpen ? 'open' : ''
    },
    // 时间选择器回调
    onChangeTrackTableData (value) {
      this.$emit("onChangeTrackTableData", value)
    }
  },
}
</script>

<style lang='scss' scoped>
@import "@/scss/iviewCssReset/tabs";
@import "@/scss/iviewCssReset/input";

::v-deep {
  // tabs
  .ivu-tabs-bar {
    margin-bottom: 0;
  }

  .ivu-tabs-nav-scroll {
    background: #4e5357;
    display: flex;
    justify-content: space-around;
  }

  // 时间轴
  .ivu-timeline-item-tail {
    border-left: 1px solid rgba(255, 255, 255, 0.1);
  }

  // 气泡提示
  .ivu-poptip-inner {
    background-color: rgba(25, 34, 41, 0.85);
  }
}

.right {
  position: absolute;
  top: 50px;
  right: 8px;
  z-index: 201;
  box-sizing: border-box;
  box-shadow: 0 0 16px 0 rgba(95, 99, 106, 0.17);
  background: rgba(25, 34, 41, 0.85);
  border-radius: 8px;
  width: 360px;
  // height: 877px;
  overflow: auto;

  .right-title {
    display: flex;
    justify-content: space-between;
    align-content: center;
    color: #eee;
    font-size: 18px;
    padding: 15px 16px 15px 16px;
  }

  .right-timeline {
    padding: 24px 16px;
    .timeline-item {
      cursor: pointer;
      .all-status {
        display: flex;
        align-items: center;
        color: rgba(255, 255, 255, 0.6);
        margin-bottom: 12px;
        p {
          border-style: solid;
          border-width: 1px;
          padding: 0 7px;
          margin-right: 7px;
          color: rgba(228, 228, 228, 0.85);
        }
        .p-one {
          border-color: #e3170d;
          background: rgba(176, 23, 31, 0.3);
        }
        .p-tow {
          border-color: #e19f1b;
          background: rgba(255, 153, 18, 0.3);
        }
        .p-three {
          border-color: #c6c6c6;
          background: rgba(201, 201, 201, 0.3);
        }
        .p-four {
          border-color: #0ac194;
          background: rgba(40, 240, 98, 0.3);
        }
      }

      .all-text-one {
        color: rgba(233, 104, 112, 0.85);
        font-weight: bold;
      }
      .all-text-tow {
        color: rgba(255, 153, 18, 0.85);
        font-weight: bold;
      }
      .all-text {
        color: rgba(255, 255, 255, 0.85);
      }
    }
  }
}
.open {
  height: 877px;
}
.control-box {
  position: absolute;
  right: 375px;
  bottom: 52px;
  background: rgba(25, 34, 41, 0.85);
  padding: 5px 8px;
  z-index: 200;
  li {
    cursor: pointer;
    padding: 10px 0;
    .layer-list {
      padding: 10px;
      .layer-list-item {
        display: flex;
        flex-wrap: wrap;
        border-bottom: 1px solid rgba(255, 255, 255, 0.15);
        color: rgba(255, 255, 255, 0.65);
        padding-top: 10px;
        div {
          width: 100%;
          margin-bottom: 10px;
        }
      }
      .layer-list-item:last-child {
        border-bottom: none;
      }
      .layer-list-switch {
        div {
          display: flex;
          p {
            margin-right: 5px;
          }
        }
      }

      .layer-list-pattern {
        .layer-list-pattern-btn {
          li {
            cursor: pointer;
            padding: 10px 0;
            div {
              width: 50px;
              line-height: 26px;
              text-align: center;
              background: #2dbd9a;
              border-color: #2dbd9a;
              color: #fff;
              border-radius: 4px;
            }
          }
        }
      }
    }
  }
}
.arrest {
  .arrest-item {
    padding: 26px 24px 0 12px;
    color: rgba(255, 255, 255, 0.65);
    .arrest-item-title {
      display: flex;
      align-items: center;
      font-size: 14px;
      margin-bottom: 17px;
      p {
        border: 1px solid rgb(198, 198, 198);
        padding: 4px 7px;
        margin-right: 7px;
        color: rgba(228, 228, 228, 0.85);
        background: rgba(201, 201, 201, 0.3);
      }
    }
    .arrest-item-time {
      color: rgba(255, 255, 255, 0.85);
      margin-left: 56px;
    }
  }
}

.poptip-cont {
  color: #ccc;
  span {
    margin-right: 6px;
  }
}
</style>