<template>
  <div class="left">
    <div class="left-msg">
      <div class="msg-cont">
        <div class="msg-cont-top">
          <p>{{name}}</p>
          <span v-if="oldType == 'car'">{{carType}}</span>
        </div>
        <div class="msg-cont-echar"
             v-if="oldType == 'car' && refresh">
          <div>
            <Echarts width="147px"
                     height="125px"
                     key="oil"
                     :options="oilOptions"></Echarts>
          </div>
          <div>
            <Echarts width="147px"
                     height="125px"
                     key="speed"
                     :options="speedOptions"></Echarts>
          </div>
        </div>
        <div class="msg-cont-time">时间：{{pointtimestamp}}</div>
        <div class="msg-cont-site">地址：{{pointlatlon}}</div>
        <div class="msg-cont-search">
          <Tabs :animated=false>
            <TabPane label="按时间查询">
              <div class="tabs-cont-time">
                <ul class="tabs-cont-time-title">
                  <li id="today"
                      :class="currentDay==0?'current':''"
                      @click="searchTrack(0)">今天</li>
                  <li id="yestoday"
                      :class="currentDay==1?'current':''"
                      @click="searchTrack(1)">昨日</li>
                  <li id="threeday"
                      :class="currentDay==2?'current':''"
                      @click="searchTrack(2)">近三天</li>
                </ul>
                <DatePicker :value="dateInterval"
                            :editable="false"
                            @on-change="dpChageDate"
                            type="datetimerange"
                            placeholder=""
                            :options="dateOptions"
                            style="width: 278px"
                            class="time-picker"></DatePicker>
              </div>
            </TabPane>
            <TabPane label="按班次查询">
              <div class="tabs-cont-classes">
                <DatePicker type="date"
                            placeholder=""
                            style="width: 278px"></DatePicker>
                <Select v-model="classesValue"
                        style="width:278px">
                  <Option v-for="item in classesList"
                          :value="item.value"
                          :key="item.value">{{ item.label }}</Option>
                </Select>
              </div>
            </TabPane>
          </Tabs>
          <div class="msg-cont-search-btns"
               :disabled="disabled"
               @click="getTracks()">
            <div class="msg-cont-search-btns-item">查询轨迹</div>
          </div>
        </div>
        <div class="msg-cont-total"
             v-if="oldType == 'car'">
          <p>总里程:<span>{{mileage.toFixed(2)}}km</span></p>
          <p>油耗:<span>{{oilMassTotal.toFixed(2)}}L</span></p>
        </div>
        <div class="mileage-box"
             v-if="oldType == 'car'">
          <div class="mileage-box-text">
            <p>百公里油耗:</p>
            <span>{{oilMassHundred == '- -' ? oilMassHundred : oilMassHundred.toFixed(2) + 'L'}}</span>
          </div>
        </div>
        <div v-if="carTypes != '3'">
          <div class="mileage-box"
               v-if="oldType == 'car'">
            <div class="mileage-box-text">
              <p>作业里程:</p>
              <span>{{homeworkMileage.toFixed(2)}}km</span>
            </div>
          </div>
          <div class="mileage-box"
               v-if="oldType == 'car'">
            <div class="mileage-box-text">
              <p>空驶里程:</p>
              <span>{{emptyMileage.toFixed(2)}}km</span>
            </div>
            <!-- <Progress :percent="100"
                    hide-info
                    :stroke-color="['#339BC1','#318EC1']" /> -->
          </div>
        </div>
      </div>
    </div>
    <div class="left-bottom"
         v-if="carTypes == '3'">
      <div>
        <div class="bin-box-big flex a-center j-between">
          收集点
          <Icon custom="i-icon icon-jiantou2"
                size="14"
                color="#fff"
                class="cursor-pointer no-clean"
                @click.stop="noCleanVisible = !noCleanVisible"
                :class="{'no-clean-show' : noCleanVisible}" />
        </div>
        <div class="bin-box"
             v-show="noCleanVisible">
          <p @click="onclickBinTabs(1)"
             :class='classBinDone'>已清点位：<span>{{binDoneNum}}</span></p>
          <p @click="onclickBinTabs(2)"
             :class='classBinPending'>未清点位：<span>{{binPendingNum}}</span></p>
          <!-- <p v-for="(item,index) in binTabs"
             :key="index"
             @click="onclickBinTabs(index)">{{item.name}}<span>（{{item.num}}）</span></p> -->
        </div>
      </div>
      <div v-if="((binTabsIndex == 1 && binDoneList.length != 0) || (binTabsIndex == 2 && binPendingList.length != 0))"
           class="bin-list-big"
           v-show="noCleanVisible">
        <div v-if="binTabsIndex ==1"
             class="bin-list">
          <div v-for="(item,index) in binDoneList"
               :key="index"
               @click="onClickBin(item)">
            <Icon custom="i-icon icon-leixing_lajitong"
                  size="18"
                  color="#5ed3b7" />
            <span>{{item.faclname}}</span>
          </div>
        </div>
        <div v-else
             class="bin-list">
          <div v-for="(item,index) in binPendingList"
               :key="index"
               @click="onClickBin(item)">
            <Icon custom="i-icon icon-leixing_lajitong"
                  size="18"
                  color="#faad14" />
            <span style="color:#faad14">{{item.faclname}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import MyCollapse from "../../../common/MyCollapse";
import MyTreeSelect from '@/components/common/MyTreeSelect';
import Echarts from '../../../common/Echarts';
import bus from '@/utils/bus'

export default {
  components: {
    MyCollapse,
    MyTreeSelect,
    Echarts
  },
  props: {
    oldType: String,
    name: String,
    oilMass: Number,
    carId: String,
    oilMassTotal: Number,
    homeworkMileage: Number,
    oilMassHundred: {
      type: Number | String
    },
    emptyMileage: Number,
    mileage: Number,
    speed: Number,
    carType: String,
    dateInterval: Array,
    pointtimestamp: String,
    pointlatlon: String,
  },
  watch: {
    oilMass (v1, v2) {
      this.oilOptions.series[0].data[0].value = this.oilMass;
      this.refresh = false;
      this.$nextTick(() => {
        this.refresh = true;
      })
    },
    speed (v1, v2) {
      this.speedOptions.series[0].data[0].value = this.speed;
      this.refresh = false;
      this.$nextTick(() => {
        this.refresh = true;
      })
    },
    watch: {
    },
  },
  computed: {
    ...mapGetters({
      // 'departmentTreeArr': 'getDepartmentTreeList',
    }),
    binTabs () {
      let arr = [
        { name: '已清', num: this.binDoneNum },
        { name: '未清', num: this.binPendingNum }
      ]
      return arr
    },
  },
  data () {
    return {
      dateOptions: {
        disabledDate (date) {
          return date && date.valueOf() > Date.now()
        }
      },
      refresh: true,
      disable: true,
      speedData: 10,
      currentDay: 0,
      classesValue: '',
      classesList: [],
      carPicture: require('../../../../assets/main/carImg.png'),
      personPhoto: require('../../../../assets/main/personImg1.png'),
      oilOptions: {
        series: [{
          type: 'gauge',
          startAngle: 180,
          endAngle: 0,
          min: 0,
          max: 240,
          splitNumber: 12,
          // 指针样式
          itemStyle: {
            color: '#17CE66',
            // shadowColor: 'rgba(0,138,255,0.45)',
            // shadowBlur: 10,
            // shadowOffsetX: 2,
            // shadowOffsetY: 2
          },
          animationThreshold: false, // 开启/关闭动画
          // 展示当前进度
          progress: {
            show: true,
            roundCap: true,
            width: 18
          },
          // 仪表盘指针
          pointer: {
            width: 2,
            offsetCenter: [0, '5%']
          },
          // 轴线相关
          axisLine: {
            roundCap: true,
            lineStyle: {
              width: 6,
              color: [[1, '#1AD270']]
            }
          },
          // 刻度样式
          axisTick: {
            show: false,
            splitNumber: 2,
            lineStyle: {
              width: 2,
              color: '#999'
            }
          },
          // 分割线样式
          splitLine: {
            show: false,
            length: 12,
            lineStyle: {
              width: 3,
              color: '#999'
            }
          },
          // 刻度标签
          axisLabel: {
            show: false,
            distance: 30,
            color: '#999',
            fontSize: 10
          },
          title: {
            show: true,
            offsetCenter: [0, '50%'],
            fontSize: 10,
            color: '#8F9896',
          },
          detail: {
            valueAnimation: true,
            fontSize: 20,
            color: '#fff',
            offsetCenter: [0, '90%']
          },
          data: [{
            value: 0,
            name: '油量(L)'
          }]
        }]
      },
      speedOptions: {
        series: [{
          type: 'gauge',
          startAngle: 180,
          endAngle: 0,
          min: 0,
          max: 240,
          splitNumber: 12,
          // 指针样式
          itemStyle: {
            color: '#FFA10C',
          },
          animationThreshold: false, // 开启/关闭动画
          // 展示当前进度
          progress: {
            show: true,
            roundCap: true,
            width: 18
          },
          // 仪表盘指针
          pointer: {
            width: 2,
            offsetCenter: [0, '5%']
          },
          // 轴线相关
          axisLine: {
            roundCap: true,
            lineStyle: {
              width: 6,
              color: [[1, '#FFA10C']]
            }
          },
          // 刻度样式
          axisTick: {
            show: false,
            splitNumber: 2,
            lineStyle: {
              width: 2,
              color: '#999'
            }
          },
          // 分割线样式
          splitLine: {
            show: false,
            length: 12,
            lineStyle: {
              width: 3,
              color: '#999'
            }
          },
          // 刻度标签
          axisLabel: {
            show: false,
            distance: 30,
            color: '#999',
            fontSize: 10
          },
          title: {
            show: true,
            offsetCenter: [0, '50%'],
            fontSize: 10,
            color: '#8F9896',
          },
          detail: {
            valueAnimation: true,
            fontSize: 20,
            color: '#fff',
            offsetCenter: [0, '90%']
          },
          data: [{
            value: 0,
            name: '速度(km/h)'
          }]
        }]
      },
      disabled: 'true',
      carTypes: '',
      binDoneNum: 0,
      binPendingNum: 0,
      classBinDone: 'bin-bit-done',
      classBinPending: '',
      binDoneList: [],
      binPendingList: [],
      binTabsIndex: 1,
      noCleanVisible: false
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    ...mapActions([
      'updateDepartmentTreeList',
      'updateCarTypeAllList',
      'updateWorkTypeAllList'
    ]),
    searchTrack (pos) {
      this.currentDay = pos;
      this.$emit("setDateInterval", pos);
    },
    init () {
      this.updateDepartmentTreeList()
      this.updateCarTypeAllList()
      this.updateWorkTypeAllList()
      bus.$on('onSearchMenuType', data => {
        this.searchMenuDetailArr = []
        this.searchVisible = true
        this.searchMenuDetailVisible = true
        this.searchMenuDetailTitle = data.title
        this.searchMenuDetailTypeArr = [{ id: 0, name: '全部' }]
      })
      this.getCarWorkType()
      bus.$on('resetBinList', () => {
        this.binDoneNum = 0
        this.binDoneList = []
        this.binPendingNum = 0
        this.binPendingList = []
      })
      bus.$on('binList', obj => {
        // this.binDoneNum = this.binDoneNum + obj.cleanListVoList.length
        // this.binDoneList = this.binDoneList.concat(obj.cleanListVoList)
        // this.binPendingNum = this.binPendingNum + obj.notCleanListVoList.length
        // this.binPendingList = this.binPendingList.concat(obj.notCleanListVoList)
        this.binDoneNum = obj.cleanListVoList.length
        this.binDoneList = obj.cleanListVoList
        this.binPendingNum = obj.notCleanListVoList.length
        this.binPendingList = obj.notCleanListVoList
      })
    },
    // 获取车辆作业类型
    getCarWorkType () {
      bus.$on('carJobType', carType => {
        this.carTypes = carType
      })
    },
    getTracks () {
      // if (this.disable) {
      bus.$emit('onSwitch', 'true')
      this.$emit("getInquireTrack");
      // } else {
      //   this.$Message.info('最多选择3天')
      // }
    },
    dpChageDate (d) {
      let dTime = d[1].slice(10)
      if (dTime == ' 00:00:00') {
        let d1 = d[1].slice(0, 11)
        d[1] = d1 + ' 23:59:59'
      }
      let time1 = new Date(d[0]).getTime()
      let time2 = new Date(d[1]).getTime()
      let time3 = time2 - time1
      // if (time3 > 259199000) {
      //   this.disable = false
      // } else {
      this.disable = true
      this.$emit("setDateInterval2", d);
      // }
    },
    // 收集点tab栏切换
    onclickBinTabs (index) {
      if (index == 1) {
        this.classBinDone = 'bin-bit-done'
        this.classBinPending = ''
        this.binTabsIndex = 1
      } else {
        this.classBinDone = ''
        this.classBinPending = 'bin-bit-pending'
        this.binTabsIndex = 2
      }
    },
    // 点击收集点
    onClickBin (item) {
      this.$emit('onClickBin', item)
    },
  }
}
</script>

<style lang="scss" scoped>
@import "@/scss/iviewCssReset/modal";
@import "@/scss/iviewCssReset/drawer";
@import "@/scss/iviewCssReset/radio";
@import "@/scss/iviewCssReset/input";
@import "@/scss/iviewCssReset/checkbox";
@import "@/scss/iviewCssReset/tabs";
@import "@/scss/iviewCssReset/datePicker";
@import "@/scss/iviewCssReset/select";
@import "@/scss/iviewCssReset/progress";
@import "@/scss/iviewCssReset/tree";

::v-deep {
  // tabs
  .ivu-tabs-bar {
    margin-bottom: 0;
  }
  .ivu-tabs-nav-scroll {
    background: #4e5357;
    display: flex;
    justify-content: space-around;
  }
  // datepicker
  .ivu-date-picker-rel .ivu-input {
    background-color: #111315;
  }
  .ivu-btn-text {
    background-color: white;
  }
}
.left {
  width: 310px;
  position: absolute;
  left: 8px;
  top: 40px;
  z-index: 200;
  .left-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 42px;
    color: rgba(255, 255, 255, 0.85);
    font-size: 28px;
    margin: 12px 0;
    background: rgba(25, 34, 41, 0.85);
    border-radius: 8px;
    padding: 0 16px;
    box-sizing: border-box;
    p {
      font-weight: bold;
      font-size: 18px;
      color: #fff;
    }
    .left-title-icon {
      display: block;
      font-size: 28px;
      text-align: center;
      cursor: pointer;
    }
  }
  .left-msg {
    background: rgba(25, 34, 41, 0.85);
    box-shadow: 0 0 16px 0 rgba(95, 99, 106, 0.17);
    border-radius: 6px;
    .msg-cont {
      padding: 8px;
      .msg-cont-top {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        padding: 10px 13px;
        p {
          color: #eee;
          font-size: 20px;
          font-weight: bold;
          max-width: 200px;
          word-break: break-all;
        }
        span {
          font-size: 14px;
          font-weight: 500;
          background: rgba(255, 255, 255, 0.2);
          border-radius: 15px;
          padding: 6px 7px;
          color: #eee;
        }
      }
      .msg-cont-echar {
        display: flex;
        padding: 10px 0;
        box-sizing: border-box;
        position: relative;
        min-height: 35px;
      }
      .msg-cont-time {
        font-size: 14px;
        color: #a5a5a5;
        line-height: 20px;
        padding: 0 17px;
      }
      .msg-cont-site {
        font-size: 14px;
        color: #a5a5a5;
        line-height: 20px;
        padding: 0 17px;
        padding-bottom: 10px;
      }
      .msg-cont-search {
        .tabs-cont-time {
          padding: 10px 8px;
          .tabs-cont-time-title {
            display: flex;
            padding: 8px 0 25px 0;
            li {
              color: rgba(255, 255, 255, 0.85);
              margin-right: 27px;
              cursor: pointer;
            }
            .current {
              color: #25bb96;
            }
          }
          // .time-picker {
          //   .ivu-date-picker-cells-cell-range:before {
          //     background: #25bb96;
          //   }
          // }
        }
        .tabs-cont-classes {
          padding: 16px 8px;
        }
        .msg-cont-search-btns {
          padding: 0 8px 10px 8px;
          border-bottom: 1px solid rgba(240, 240, 240, 0.1);
          .msg-cont-search-btns-item {
            width: 88px;
            line-height: 32px;
            color: #ffffff;
            background: #398e79;
            border: 0;
            border-radius: 0;
            padding: 0 16px;
            cursor: pointer;
          }
        }
      }
      .msg-cont-total {
        padding: 10px 8px 5px;
        display: flex;
        justify-content: space-between;
        p {
          color: rgba(255, 255, 255, 0.7);
          span {
            color: #fff;
          }
        }
      }
      .mileage-box {
        padding: 0 8px 5px 8px;
        .mileage-box-text {
          display: flex;
          justify-content: space-between;
          padding: 5px 0;
          p {
            color: rgba(255, 255, 255, 0.65);
          }
          span {
            color: rgba(255, 255, 255, 0.85);
          }
        }
      }
    }
  }
  .left-bottom {
    margin-top: 5px;
    background: rgba(25, 34, 41, 0.85);
    box-shadow: 0 0 16px 0 rgba(95, 99, 106, 0.17);
    border-radius: 6px;
    padding: 5px 10px;
  }
}
.bin-box-big {
  color: #fff;
  padding: 5px 8px;
  font-size: 16px;
  // border-top: 1px solid rgba(255, 255, 255, 0.3);
  .no-clean {
    transition: 0.2s;
  }
  .no-clean-show {
    transform: rotate(90deg);
  }
}
.bin-box {
  padding: 0px 8px;
  padding-top: 5px;
  display: flex;
  justify-content: space-between;
  p {
    color: rgba(255, 255, 255, 0.7);
  }
  .bin-bit-done {
    color: #5ed3b7;
  }
  .bin-bit-pending {
    color: #faad14;
  }
}
.bin-box :hover {
  cursor: pointer;
}
.bin-list-big {
  border: 1px solid #5ed3b7;
  border-radius: 6px;
  margin-top: 10px;
  padding-bottom: 5px;
}
.bin-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  color: #5ed3b7;
  // background-color: #ccc;
  padding: 10px;
  font-size: 16px;
  max-height: 120px;
  overflow-y: auto;
  // margin: 10px 0;
  div {
    display: flex;
    align-content: center;
    width: 40%;
    cursor: pointer;
    margin-bottom: 10px;
    span {
      margin-left: 5px;
    }
  }
}
.bin-list::-webkit-scrollbar-thumb {
  border-radius: 10px;
}
</style>
